.host-settings-menu {
    height: 100%;
    background-color: rgba(255, 190, 136, 0.04);
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    border-right-width: 1px;
    border-right-color: #CCC3BD;
    border-right-style: solid;

    .menu-list {
        padding: 0;
        margin-top: 40px;
        list-style-type: none;

        .menu-item-container {
            position: relative;

            .back-btn {
                width: 100%;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                
                .back-btn-label-wrapper {
                    padding: 8px 0 8px 46px;
                    width: 100%;
                    align-items: center;
                    display: flex;

                    .back-btn-icon {
                        border: 1px solid #cfc8bb;
                        color: #cfc8bb;
                        border-radius: 50%;
                        width: 36px;
                        height: 36px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        outline: none;
                        margin-right: 1ch;

                        svg {
                            font-size: 18px;
                        }
                    }

                    .back-btn-label {
                        font-size: 18px;
                        text-align: left;
                    }
                }


            }

            .menu-item {
                width: 100%;
                text-align: left;
                font-size: 22px;
                line-height: 32px;
                display: flex;

                &::after {
                    content: '';
                    background-color: #FFFFFF;
                    border: 1px solid #CCC3BD;
                    border-left-width: 0;
                    border-right-width: 0;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    opacity: 0;
                    will-change: opacity;
                    transition: .2s opacity;
                }

                .menu-item-label {
                    padding: 8px 0 8px 46px;
                    z-index: 1;
                }

               
            }

            &.terms-menu {
                .menu-item {
                    opacity: .4;
                    will-change: opacity;
                    transition: .2s opacity;
                    font-size: 20px;

                    &:hover {
                        opacity: 1;
                    }
                }
            }

            &.active {
                &.terms-menu {
                    .menu-item {
                        opacity: 1;
                    }
                }

                .menu-item {
                    &::after {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
