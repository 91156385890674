.chat-sections {
  display: flex;
  flex-flow: column;
  overflow: hidden;
}

.pending-header {
  border-top-left-radius: 30px;
  background-color: var(--peach-color-60);
  display: flex;
  flex-flow: column;
  border-right-width: 1px;
  border-right-color: var(--peach-color);
  border-right-style: solid;
  border-bottom: var(--peach-color) 1px solid;

  .row {
    display: flex;
    flex-direction: row;

    .indicator {
      border-radius: 50%;
      text-align: center;
      width: 22px;
      height: 22px;
      font-size: 14px;
      background: var(--error-color);
      color: white;
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  p {
    font-size: 18px;
    margin-left: 20px;
    margin-top: 5px;
  }

  .back-btn {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .back-btn-label-wrapper {
      padding: 7px 0 4px 20px;
      width: 100%;
      align-items: center;
      display: flex;

      .back-btn-icon {
        border: 1px solid #cfc8bb;
        color: #cfc8bb;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        margin-right: 1ch;

        svg {
          font-size: 18px;
        }
      }

      .back-btn-label {
        font-size: 18px;
        text-align: left;
      }
    }
  }
}

.pending-menu {
  overflow-y: hidden;
  display: flex;
  flex-flow: column;
  border-right-width: 1px;
  border-right-color: #CCC3BD;
  border-right-style: solid;
  border-bottom: #CCC3BD 1px solid;

  .row {
    display: flex;
    flex-direction: row;

    .indicator {
      border-radius: 50%;
      text-align: center;
      width: 22px;
      height: 22px;
      font-size: 14px;
      background: var(--error-color);
      color: white;
      margin-left: 10px;
    }
  }

  p {
    font-size: 18px;
    margin-left: 20px;
  }

  .menu-list {
    padding: 0;
    list-style-type: none;
    overflow-y: auto;

    .menu-item-container {
      background-color: var(--peach-color-60);
      position: relative;
      display: flex;
      flex-flow: column;

      hr {
        height: 1px;
        background-color: #ccc;
        margin: 10px 10px 0px 10px;
      }

      .gray {
        background-color: var(--peach-color);
      }

      .space {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px 10px 0px 10px;
      }
    }
  }
}

.active-menu {
  background-color: white;
  display: flex;
  flex-flow: column;
  border-right-width: 1px;
  border-right-color: #CCC3BD;
  border-right-style: solid;

  p {
    font-size: 18px;
    margin-top: 4px;
    margin-left: 20px;
  }

  .menu-list {
    padding: 0;
    margin-top: 4px;
    list-style-type: none;
    border-top: #CCC3BD 1px solid;
    overflow-y: auto;

    .menu-item-container {
      position: relative;
      display: flex;
      flex-flow: column;

      hr {
        height: 1px;
        background-color: #ccc;
        margin: 10px 10px 0px 10px;
      }

      .white {
        background-color: white;
      }
      .gray {
        background-color: var(--peach-color);
      }

      .space {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 5px 10px 5px 10px;

        .indicator {
          border-radius: 50%;
          text-align: center;
          width: 24px;
          height: 24px;
          font-size: 15px;
          background: var(--error-color);
          color: white;
        }

        .image {
          display: flex;
          flex-direction: row;

          .icon {
            color: var(--base-font-color);
            margin-left: 5px;
          }
        }

        .row {
          display: flex;
          flex-direction: row;
        }
      }

      h6 {
        margin-top: 5px;
        font-size: 12px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);
      }
      h5 {
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);
      }
    }
  }
}

.smallPending {
  height: 0px;
}
.onePending {
  height: 76px;
}
.twoPending {
  height: 152px;
}
.threePending {
  height: 228px;
}
.fourPending {
  height: 304px;
}
.bigActive {
  height: calc(100% - 80px);
}
.oneActive {
  height: calc(100% - 76px - 80px);
}
.twoActive {
  height: calc(100% - 152px - 80px);
}
.threeActive {
  height: calc(100% - 228px - 80px);
}
.fourActive {
  height: calc(100% - 304px - 80px);
}
