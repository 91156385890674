.host-kpis {
  display: flex;
  position: relative;
  flex-grow: 1;

  .kpi-section-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: auto;
  }

  .kpi-wrapper {
    article {
      padding: 32px 32px 0;
  
      &:after {
        content: '';
        border-bottom: 1px solid #ccc3bd;
        width: 255px;
        height: 1px;
        display: block;
        margin-top: 16px;
      }
  
      &:last-child {
        &:after {
          border-bottom: none;
        }
      }
    }
  }

  .kpi-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon-align {
      transform: rotate(0);
      transition: .2s ease-in-out transform;

      &.up {
        transform: rotate(-180deg);
      }
    }

    h3 {
      margin: unset;
      font-weight: unset;

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .kpi-items-container {
    list-style-type: none;
    padding: unset;

    &.closable {
      overflow: hidden;
      max-height: 0;
      transition: .4s ease-in-out max-height;
    }

    &.open {
      max-height: 500px;
    }

    li {
      font-size: 14px;
    }
  }

  .chart {
    padding-top: 8px;
    display: flex;
    justify-content: center;
  }
}
