.call-preparation-tab {
  visibility: hidden;
  position: absolute;
  transform: translateX(450px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 12px;
  background-color: white;

  .stale-data {
    font-size: 12px;
    color: red;
  }

  .call-preparation-tab-header {
    justify-content: space-around;
    display: grid;
    grid-template-columns: 2fr .5fr .5fr;
    align-items: center;
    margin-top: 10px;

    &.edit-mode {
      grid-template-columns: auto auto;
      justify-content: space-between;

      .back-icon {
        cursor: pointer;
      }
    }

    .preset-button {
      color: blue;
      -webkit-text-fill-color: blue;
      cursor: pointer;
      justify-self: flex-end;

      &.disabled {
        color: gray;
        -webkit-text-fill-color: grey;
        cursor: default;
      }
    }
  }

  .call-preparation-products-counter {
    font-size: 12px;
    margin-left: 4px;
    .limit {
      color: var(--error-color);  
    }    
  }
  
  .call-preparation-tab-products-list {
    flex-direction: column;
    display: flex;
    flex: 1;
    overflow-y: auto;

    .search-product-item-component {
      padding: 0;

      &:hover {
        background: rgba(0, 0, 0, 0.04);
        cursor: default;
      }

      .product-description-wrapper {
        .remove-selected-product-product {
          width: 35px;
          margin-right: 0;
        }
      }
    }

    .empty-results {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      p {
        display: block;
        margin-top: 24px;
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }

  &.visible {
    visibility: visible;
    transform: translateX(0px);
    z-index: 1;
  }
}