.permissions-helper-container {
  min-height: 400px;
  min-width: 600px;
  width: 50%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .permissions-helper-logo {
    width: 330px;
    height: 221px;
    margin-bottom: 24px;
  }

  .permissions-helper-info {
    font-size: 16px;
    margin-bottom: 24px;
    width: 80%;
    text-align: center;
  }

  .permissions-helper-button {
    margin-top: 12px !important;
    text-transform: capitalize !important;
  }
}
