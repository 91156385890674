.error-message {
    color: var(--error-color);
    font-size: 14px;
    display: flex;
    align-items: center;
 }

 .popover-message-container-wrapper {
     overflow: hidden;
     max-height: 200px;

    .popover-message-container {
        .message {
            display: flex;
            align-items: center;
            font-size: 14px;
            margin-top: .7em;

            &.error {
                color: var(--error-color);
            }

            &.warning {
                color: var(--warning-color);
            }
        }
    }
 }
