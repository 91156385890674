.image-with-overlay {
  margin: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border: 3px solid var(--main-color);
    opacity: 0;
    transition: .14s opacity;
    border-radius: 16px;
  }

  &.draggable {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &.highlighted {
    &::before {
      opacity: 1;
    }
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 16px;
    display: flex;
    cursor: pointer;
  }

  .close-icon-container {
    position: absolute;
    top: -12px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  img {
    border-radius: 16px;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    width: 100%;

    &.animated {
      opacity: 0;
      
      &.loaded {
        animation: .2s showImage .2s forwards;
      }
    }
  }
}

.image-with-overlay-no-click {
  margin: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &.draggable {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 0;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    &:hover {
      opacity: 1;
    }
  }

  img {
    border-radius: 16px;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
}

@keyframes showImage {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}
