.app-settings-container {
  display: flex;
  justify-content: flex-start;

  .app-settings-wrapper {
    max-width: 500px;
    min-width: 400px;
    width: 100%;
    margin: 50px;

    .setting-display-active-shops {
      align-items: center;
      display: flex;
      justify-content: space-between;

      .setting-title {
        font-size: 18px;
        color: #3f3f3f;
        -webkit-text-fill-color: #3F3F3F;
        font-weight: 500;
      }

      label {
        margin-bottom: 0;
      }

    }
  }


}