.draft-order-discount {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;

  .draft-order-discount-container {
    padding: 20px 0;

    .input-symbol {
      position: relative;
    }

    .input-symbol:after {
      position: absolute;
      top: 0;
      right: 8px;
      margin-top: 14px;
      content: attr(symbol);
    }

    .section {
      display: grid;
      grid-row: 2;
      grid-template-rows: 20px auto;

      input[type='number'] {
        -moz-appearance:textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    .draft-order-discount-amount {
      display: grid;
      grid-template-columns: 45% 45%;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .draft-order-discount-value {
      box-sizing: border-box;
      border: 1px solid #CCC3BD;
      border-radius: 8px;
      margin-top: 8px;
      padding-left: 10px;
      background: #FFF;
      min-height: 38px;
      width: 100%;
      padding-right: 20px;
    }

    .draft-order-discount-reason {
      box-sizing: border-box;
      border: 1px solid #CCC3BD;
      border-radius: 8px;
      margin: 10px 0;
      padding: 10px;
      width: 100%;
      background: #FFF;
    }

    p {
      color: gray;
    }
  }

  .draft-order-discount-buttons {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-self: center;
    margin: 0 0 20px 50%;
  }

  .draft-order-discount-edit-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-self: center;
    margin-bottom: 20px;
  }
}
