.diagnostic-container {
  margin: 40px;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  z-index: 9999;
  gap: 2rem;

  .back-button {
    /* position: absolute;
    top: 300px; */
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 512px) {
      align-self: center;
    }
  }

  @media (max-width: 1024px) {
    margin: 10px;
  }
}
