.modal-overlay {
  position: fixed !important;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;

  .call-details-modal {
    height: 630px;
    width: 480px;
    border-radius: 32px;
    background-color: #ffffff;
    box-shadow: inset 0 -4px 0 0 #000000;
    display: flex;
    flex-direction: column;
    position: relative;

    .close-button {
      box-sizing: border-box;
      height: 30px;
      width: 30px;
      border: 1px solid #999999;
      border-radius: 16px;
      background-color: #ffffff;
      position: absolute;
      right: 24px;
      top: 24px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }

    .back-btn {
      position: absolute;
      top: calc(28px - 1em);
      left: .5em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1em;
      cursor: pointer;
    }

    .call-menu-container {
      >svg {
        position: absolute;
        top: 24px;
        left: 24px;
        cursor: pointer;
      }

      .call-menu {
        display: none;
        width: 130px;
        padding: unset;
        list-style: none;
        padding: unset;
        font-size: 12px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);
        position: absolute;
        background: white;
        border-radius: 16px;
        z-index: 10;
        box-shadow: 0 2px 6px 2px rgba(0, 0, 0, 0.08);

        li {
          line-height: 18px;
          padding: 9px 0 9px 16px;
          cursor: pointer;

          &:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
          }

          &.delete {
            color: var(--error-color);
            -webkit-text-fill-color: var(--error-color);
          }
        }

        &.visible {
          display: flex;
          flex-direction: column;
          z-index: 100;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      .customers-message-container-wrapper {
        height: 100%;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          height: 1em;
          left: 0;
          right: 0;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          height: 1em;
          left: 0;
          right: 0;
          background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }

        .customers-message-container {
          overflow-y: scroll;
          padding: 0 1.2rem;
          height: 100%;

          .customers-message {
            margin-bottom: 0;
            font-size: 16px;
            padding: 1ch 0;
          }
        }
      }


      footer.confirm-actions {
        border: none;
        flex-direction: row;
        justify-content: center;
        padding: 20px 0;

        >div:first-child {
          cursor: pointer;
        }
      }

      section.confirm-schedule {
        height: 85%;
        padding: 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        .green-circle {
          height: 110px;
          width: 110px;
          padding-left: 5px;
          border: var(--main-color) 10px solid;
          border-radius: 50%;
        }

        .copy-link-container {
          width: 100%;
        }

        .customer-contacts-container {
          font-size: 14px;
          display: flex;
          flex-flow: row nowrap;

          .circle-divider {
            padding: 0 10px;
            position: relative;

            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate3d(-50%, -50%, 0);
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: rgba(0, 0, 0, 0.8);
            }
          }
        }

        .customers-message-wrapper {
          position: relative;
          width: 100%;
          display: flex;
          flex-flow: column;
          justify-content: flex-start;
          align-items: flex-end;
          margin-bottom: 18px;

          .customers-message-content {
            background-color: #ebebeb;
            border-radius: 16px;
            position: relative;
            padding: 16px 16px 0 16px;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            width: 100%;

            &.no-overflows {
              padding: 16px;
            }

            .customers-message {
              display: -webkit-box;
              font-size: 14px;
              -webkit-line-clamp: 4;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: start;
              margin-bottom: 0;
            }

            button {
              font-size: 14px;
              padding: 10px 0 5px 16px;
              align-self: flex-end;
            }
          }
        }

        h2,
        p {
          color: var(--base-font-color);
          -webkit-text-fill-color: var(--base-font-color);
          margin-bottom: 16px;
          text-align: center;
        }

        .confirm-header {
          text-align: center;
       }
      }

      >p.error-text {
        position: absolute;
        bottom: 0;
      }
    }

    .modal-overlay {
      right: 0;
    }
  }
}
