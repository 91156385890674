.summary-data-container {
  flex: 1 1;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: -20px;

    h3 {
      margin-bottom: unset;
    }
  }

  .summary-date-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    border-bottom: 1px solid LightGrey;

    .date-title {
      font-size: 16px;
      margin-bottom: 6px;
    }

    .date {
      font-weight: 500;
      margin-bottom: 6px;
    }

    .duration {
      color: rgba(0, 0, 0, 0.64);
      -webkit-text-fill-color: rgba(0,0,0,.64);
    }

    .event-type-container {
      display: flex;
      flex-flow: row;
      margin-top: 4px;
      margin-bottom: 6px;

      .event-type-color {
        background-color: var(--event-type-color);
        width: 10px;
        height: 100%;
        margin-right: 12px;
      }
    }
  }

  .summary-customer-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    justify-content: center;
    border-bottom: 1px solid LightGrey;

    .customer-details-title {
      font-size: 16px;
      margin-bottom: 6px;
    }

    .client-name {
      margin-bottom: 0;
      color: rgba(0, 0, 0, 0.84);
      -webkit-text-fill-color: rgba(0,0,0,.84);
    }

    .client-email {
      color: rgba(0, 0, 0, 0.84);
      -webkit-text-fill-color: rgba(0,0,0,.84);
      margin-bottom: 6px;
    }

    .timezone {
      display: flex;
      color: rgba(0, 0, 0, 0.84);
      -webkit-text-fill-color: rgba(0,0,0,.84);

      .change-button {
        margin: 0 10px 0 10px;
        color: blue;
        -webkit-text-fill-color: blue;
        cursor: pointer;
      }
    }
  }

  .summary-call-details-container {
    flex: 0.7;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

}