.scheduled-actions-container {
  width: 100%;
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  height: 100%;
  max-width: 420px;
  visibility: hidden;
  transition-delay: 0ms;

  .row {
    align-items: center;
    cursor: pointer;
    padding: 4px;
    position: relative;

    .tooltiptext {
      visibility: hidden;
      display: inline;
      width: 160px;
      background-color: #6e76f2;
      border-radius: 4px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      top: 100%;
      left: 50%;
      margin-left: -60px;
      font-size: 14px;
      color: white;
      -webkit-text-fill-color: white;
      text-align: center;
    }

    &:hover .tooltiptext {
      visibility: visible;
    }

    .action-text {
      margin-left: 4px;
      font-size: 14px;
      color: #6e76f2;
      -webkit-text-fill-color: #6e76f2;

      &.hidden {
        display: none;
      }
    }

    .min-width {
      min-width: 90px;
      align-items: center;
      justify-content: center;
    }

    @media screen and (max-width: 1400px) {
      .min-width  {
        min-width: 30px;
      }
    }

    .more-call-action-modal {
      opacity: 0;
      position: absolute;
      width: 200px;
      background: #fff;
      z-index: 1;
      display: flex;
      flex-direction: column;
      right: 0;
      align-items: flex-start;
      justify-content: space-evenly;
      border-radius: 6px;
      margin: auto;
      box-sizing: border-box;
      outline: none;
      border: none;
      box-shadow: 0 0 14px rgba(0, 0, 0, 0.30);
      transition: .14s ease-in-out all;
      pointer-events: none;
      will-change: opacity, transform;

      &.top {
        top: auto !important;
        bottom: 30px;
        transform: translateY(-20px);

        &:after {
          bottom: -7px;
          border-top: 8px solid #fff;
        }
      }

      &.bottom {
        top: 30px;
        transform: translateY(20px);

        &:after {
          top: -7px;
          border-bottom: 8px solid #fff;
        }
      }

      &.visible {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }

      ul {
        width: 100%;
        list-style: none;
        padding: unset;
        margin: 8px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);

        li {
          line-height: 32px;
          cursor: pointer;
          width: 100%;

          &.red {
            color: red;
            -webkit-text-fill-color: red;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .scheduled-actions-container  {
    .action-text {
      display: none;
    }
  }
}
