.cart-details-tab {
  visibility: hidden;
  position: absolute;
  transform: translateX(450px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &.order {
    background-color: #F5F5F5;
  }

  .cart-details-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .cart-details-action-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      p {
        color: var(--main-color);
        font-size: 14px;
        margin-left: 4px;
      }
    }
  }

  .cart-details-products {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 16px;
    overflow: hidden;
    overflow-y: auto;

    .cart-details-product-container {
      display: flex;
      padding: 16px 0;
      border-bottom: 1px solid #e8e8e8;

      .main-image {
        border-radius: 10px;
        object-fit: contain;
        height: 80px;
        max-width: 60px;
      }

      .product-description {
        margin-left: 20px;

        span {
          display: flex;
        }

        .product-title {
          font-weight: bold;
        }

        .variant-title {
          font-weight: unset;
          font-size: 14px;
          color: grey;
        }

        .price-container {
          cursor: pointer;
        }
      }

    }

    .cart-discount {
      height: 48px;
      border: 1px solid #b5b5b5;
      border-radius: 4px;
      align-items: center;
      padding-left: 16px;
      justify-items: center;
      display: flex;
      margin-top: 12px;
    }
  }

  .cart-details-footer {

    .description-row {
      margin: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 0;
      border-bottom: 1px solid #e8e8e8;

      &.lastItem {
        border-bottom: 0 solid #e8e8e8;
      }

      p {
        color: #66676c;
        font-size: 14px;
      }
    }

    .cart-details-footer-total {
      height: 60px;
      margin: 0 16px;
      border-top: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 600;
        font-size: 14px;
        color: #3f3f3f;
      }
    }
  }

  &.visible {
    visibility: visible;
    transform: translateX(0px);
    z-index: 1;
  }
}