.rating-stars-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .rating-value {
        font-size: 16px;
        margin: 0 5px;
    }

    .distructive {
        fill: var(--error-color);
        g {
            stroke: var(--error-color);
        }
    }
    
    .neutral {
        fill: var(--warning-color);
        g {
            stroke: var(--warning-color);
        }
    }
    
    .positive {
        fill: var(--green-color);
        g {
            stroke: var(--green-color);
        }
    }
}
