.header-notification {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  min-height: 48px;
  height: 48px;

  .help-link {
    cursor: pointer;
    color: white;
    text-decoration: underline;
    -webkit-text-fill-color: white;
  }

  h4 {
    color: white;
    -webkit-text-fill-color: white;
    padding-right: 16px;
  }
  &.error {
    background-color: var(--error-color);
  }
  &.warning {
    background-color: var(--warning-color);
    position: relative;
    button:last-child {
      position: absolute;
      right: 16px;
      color: white;
      -webkit-text-fill-color: white;
      text-decoration: underline;
    }
  }
  &.success {
    background-color: #08b273;
    position: relative;
    button:last-child {
      position: absolute;
      right: 16px;
      color: white;
      -webkit-text-fill-color: white;
      text-decoration: underline;
    }
  }
}
