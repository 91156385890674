.discount-code-input-container {
    display: grid;
    padding: 14px;
    gap: 0 4px;
    border-radius: 4px;
    grid-template-columns: 24px 1fr;
    position: relative;
    min-width: 350px;
    max-width: 100%;
    transition: .2s width;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        border: 1px solid #B5B5B5;
        opacity: 1;
        transition: .2s opacity;
        will-change: opacity;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 4px;
        border: 1px solid #FEBE88;
        opacity: 0;
        transition: .2s opacity;
        will-change: opacity;
    }

    .discount-code-input-container-header {
        grid-row: 1;
        grid-column: 1 / 3;
        gap: 4px;
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        z-index: 1;

        .alert-icon-container {
            grid-row: 1;
            grid-column: 1;
            color: #FEBE88;
            -webkit-text-fill-color: #FEBE88;
            display: inherit;
            transition: .2s transform ease-in-out, .2s opacity;
            transform: translateX(-28px);
            opacity: 0;
        }

        .discount-input-wrapper {
            grid-row: 1;
            grid-column: 2;
            color: #3F3F3F;
            -webkit-text-fill-color: #3F3F3F;
            font-weight: 500;

            .discount-input {
                width: 100%;
                font-weight: 500;
                color: #3F3F3F;
                -webkit-text-fill-color: #3F3F3F;
                transition: .2s transform ease-in-out;
                transform: translateX(-28px);
                cursor: text;

                &::placeholder {
                    color: #B5B5B5;
                    -webkit-text-fill-color: #B5B5B5;
                    font-weight: 400;
                }

                &.applied {
                    transform: translateX(0);
                }
            }
        }

        .discount-code-btns-container {
            grid-row: 1;
            grid-column: 3;

            .discount-form-submit-button {
                color: var(--main-color);
                -webkit-text-fill-color: var(--main-color);
                padding: 0 2px 0 16px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 21px;
                text-transform: uppercase;
                transition: .2s opacity;
                will-change: opacity;
                display: flex;
                align-items: center;
                overflow: hidden;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    border-left: 1px solid #B5B5B5;
                    transform: .2s opacity;
                }

                &:disabled {
                    .discount-form-submit-primary-button {
                        opacity: 0.2;
                    }
                }

                .discount-form-submit-primary-button {
                    position: relative;

                    &.loading {
                        .discount-apply-btn {
                            transform: translateY(-100%);
                        }

                        .loader-wrapper {
                            transform: translate3d(-50%, -50%, 0);
                        }
                    }

                    .discount-apply-btn {
                        display: block;
                        transition: .2s opacity, .2s transform;
                    }

                    .loader-wrapper {
                        transform: translate3d(-50%, 50%, 0);
                        transition: .2s transform;
                        position: absolute;
                        top: 50%;
                        left: 50%;

                        .loader {
                            height: 1rem;
                            width: 1rem;
                            border: 1px solid white;
                            border-bottom: 1px solid var(--main-color);
                            border-right: 1px solid var(--main-color);
                            border-top: 1px solid var(--main-color);
                            animation: spin .4s linear infinite;
                            margin: 0;
                        }
                    }
                }

                .discount-decline-btn {
                    transform: translateX(100%);
                    transition: .2s transform ease-in-out;
                    color: #66676C;
                    -webkit-text-fill-color: #66676C;
                    display: inherit;
                    position: absolute;
                    right: 0;
                    opacity: 0;

                    .MuiSvgIcon-root {
                        font-size: 1.14rem;
                    }
                }

                .discount-remove-btn {
                    color: #C64646;
                    -webkit-text-fill-color: #C64646;
                    transform: translateX(100%);
                    opacity: 0;
                    position: absolute;
                    right: 0;
                    transition: .2s transform .2s;
                }
            }
        }
    }

    .discount-code-input-message-container {
        grid-row: 2;
        grid-column: 2 / 3;
        color: #66676C;
        -webkit-text-fill-color: #66676C;
        margin-right: 8px;
        z-index: 1;
        overflow: hidden;
        max-height: 0;
        transition: .2s max-height ease-in-out;
        display: flex;



        .discount-code-succeed-text-message {
            padding-top: 4px;
            overflow: hidden;
            display: flex;
            flex-flow: row nowrap;

            p {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            span {
                display: block;
                white-space: nowrap;
            }
        }

        .discount-code-error-text-message {
            padding-top: 4px;
            display: flex;
            flex-flow: row nowrap;
            color: #C64646;
            -webkit-text-fill-color: #C64646;
        }
    }


    &.applied {
        &:after {
            opacity: 1;
        }

        &:before {
            opacity: 0;
        }

        .discount-code-input-container-header {
            .alert-icon-container {
                transition: .2s transform ease-in-out, .2s opacity .1s;
                opacity: 1;
                transform: translateX(0);
            }

            .discount-input-wrapper {
                .discount-input {
                    transform: translateX(0);
                }
            }

            .discount-code-btns-container {
                .discount-form-submit-button {
                    &:before {
                        opacity: 0;
                    }

                    .discount-form-submit-primary-button {
                        .discount-apply-btn {
                            transform: translateY(-100%);
                            opacity: 0;
                        }

                        .loader-wrapper {
                            transform: translateY(-100%);
                            opacity: 0;
                        }
                    }

                    .discount-decline-btn {
                        transform: translateX(0);
                        opacity: 1;
                    }

                    .discount-remove-btn {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }

        .discount-code-input-message-container {
            max-height: 50px;
            transition: .2s max-height .3s ease-in-out;
        }
    }

    &.invalid {
        &:after {
            border: 1px solid #C64646;
        }

        .discount-code-input-container-header {
            .alert-icon-container {
                transform: translateX(-28px);
                opacity: 0;
            }

            .discount-code-btns-container {
                .discount-form-submit-primary-button {
                    .discount-apply-btn {
                        transform: translateY(-100%);

                        &.loading {
                            transform: translateY(-100%);
                        }
                    }

                    .loader-wrapper {
                        transform: translate3d(-50%, -150%, 0);

                        &.loading {
                            transform: translate3d(-50%, -150%, 0);

                        }
                    }
                }
            }
        }
    }
}


.discount-input-container {
    border: 1px solid #B5B5B5;
    border-radius: 4px;
    min-width: 356px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;

    .discount-input {
        padding: 14px;
        font-weight: 500;
        color: #3F3F3F;
        -webkit-text-fill-color: #3F3F3F;

        &::placeholder {
            color: #B5B5B5;
            -webkit-text-fill-color: #B5B5B5;
            font-weight: 400;
        }
    }

    .discount-form-submit-button {
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
        padding: 0 16px;
        margin: 14px 0;
        border-left: 1px solid #B5B5B5;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 21px;
        text-transform: uppercase;
        transition: .2s opacity;
        will-change: opacity;

        &:disabled {
            .discount-form-submit-primary-button {
                opacity: 0.2;
            }
        }
    }
}

.invalid-discount-code-message {
    color: #C64646;
    -webkit-text-fill-color: #C64646;
}

.discount-code-input-container {
    justify-self: flex-start;
}
