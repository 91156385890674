.draft-order-payment-info-item {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 10px 0;

  p {
    font-size: 14px;
    cursor: default;
  }

  .payment-info-title {
    grid-column: 1;

    &.clickable {
      color: blue;
      cursor: pointer;
    }
    &.bold {
      font-weight: bold;
    }
    &.disabled {
      color: gray;
    }
  }

  .payment-info-description {
    grid-column: 2;
    justify-self: center;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &.clickable {
      color: blue;
      cursor: pointer;
    }
    &.disabled {
      color: gray;
    }
  }

  .payment-info-value {
    grid-column: 3;
    justify-self: flex-end;

    &.bold {
      font-weight: bold;
    }
    &.disabled {
      color: gray;
    }
  }
}