.sidebar {
  overflow-y: hidden;
  display: flex;

  .tabs-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
  }

  .sidebar-header {
    width: 100%;
    background-color: var(--peach-color-60);

    box-shadow: inset 0 -1px 0 0 #ccc3bd;
    display: flex;

    .header-main-container {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr auto;
      grid-template-rows: 62px auto;
      padding: 0 20px;

      &.with-chat-icon {
        .view-toggle-wrapper {
          margin-left: 60px;
          margin-right: 0;
        }
      }

      .view-toggle-wrapper {
        align-self: center;
        justify-self: center;
        grid-column: 1;
        grid-row: 1;
      }

      .icon-chat {
        grid-column: 2;
        grid-row: 1;
        color: #cfc8bb;
        cursor: pointer;
        align-self: center;
      }

      .back-btn-container {
        grid-column: 1/3;

        &.single {
          grid-row: 1/3;
        }

        .back-btn {
          margin: 0;
        }
      }
    }

    &.large {
      padding: 24px 0;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .search-input {
        box-sizing: border-box;
        grid-column: 1/3;
        height: 42px;
        border: 1px solid #ccc3bd;
        border-radius: 42px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        align-self: center;
        margin-top: 1ch;
        input {
          margin-left: 16px;
          height: 100%;
          width: 100%;
          outline: none;
          border: none;
          font-size: 16px;
        }
        svg {
          cursor: pointer;
          color: #cfc8bb;
          -webkit-text-fill-color: #cfc8bb;
          margin-right: 16px;
        }
      }
    }
    &.large-standalone {
      flex: 0 1 120px;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      .search-input {
        box-sizing: border-box;
        grid-column: 1/3;
        grid-row: 1/3;
        align-self: center;
        height: 42px;
        border: 1px solid #ccc3bd;
        border-radius: 16px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1ch;
        input {
          margin-left: 16px;
          height: 100%;
          width: 100%;
          outline: none;
          border: none;
          font-size: 16px;
        }
        svg {
          cursor: pointer;
          color: #cfc8bb;
          -webkit-text-fill-color: #cfc8bb;
          margin-right: 16px;
        }
      }
    }
    &.small {
      flex: 0 1 8%;
      align-items: center;

      .back-btn-wrapper {
        grid-row: 1 / 3;
      }

      .icon-chat {
        grid-row: 1 / 3;
      }

      p {
        font-size: 24px;
        margin-left: 2%;
        color: rgba(0, 0, 0, 0.4);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
      }

      .icon-badges {
        border-radius: 50%;
        text-align: center;
        width: 24px;
        height: 24px;
        font-size: 14px;
        background: var(--error-color);
        position: absolute;
        right: 10px;
        top: 15px;

        .badges-text {
          color: white;
          -webkit-text-fill-color: white;
          font-size: 16px;
          margin-left: 0px;
        }
      }

    }

    .back-btn-wrapper {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
    }

    .back-btn-container {
      display: flex;
      width: 100%;
      align-items: center;
      p {
        margin-left: 2%;
      }
    }
    .back-btn {
      border: 1px solid #cfc8bb;
      color: #cfc8bb;
      -webkit-text-fill-color: #cfc8bb;
      border-radius: 50%;
      width: 4vh;
      height: 4vh;
      display: flex;
      justify-content: center;
      align-items: center;
      outline: none;
    }
  }
}

.standalone-height {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.video-height {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 37.5%;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
}

.icon-badges {
  border-radius: 50%;
  text-align: center;
  width: 24px;
  height: 24px;
  font-size: 14px;
  background: var(--error-color);
  position: absolute;
  right: 10px;
  top: 25px;

  .badges-text {
    color: white;
    -webkit-text-fill-color: white;
  }
}

.chat-block {
	height: 600px;
	width: 500px;
	position: absolute;
	bottom: 0;
	left: 0;
	box-shadow: inset 0 -4px 0 0 var(--main-color);
	border-radius: 30px;
	z-index: 999;
  background-color: white;
  border-left: #ccc 1px solid;

  &.no-show {
    visibility: hidden;
  }
}
