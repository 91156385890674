.presentation-view {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  min-width: 550px;
  background-color: transparent;
  position: absolute;
  right: 0;
  z-index: 1010;
  display: flex;
  flex-direction: row;

  &_modal {
    box-sizing: border-box;
    height: 100%;
    width: 60%;
    min-width: 550px;
    border: 1px solid #979797;
    border-radius: 8px;
    background-color: rgba(0,0,0,0.8);
    position: absolute;
    right: 0;
    z-index: 101;
    padding: 32px;
    display: flex;
    flex-direction: row;

    &_details {
      width: 35%;
      max-width: 450px;
      height: 100%;
      display: flex;
      flex-direction: column;

      &_added {
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        margin-bottom: 34px;
        width: fit-content;
        text-transform: uppercase;
        margin-top: 8px;
      }

      &_actions {
        margin-bottom: 50px;

        &_mode-btn {
          height: 40px !important;
          width: 232px !important;
          border-radius: 100px !important;
          display: flex;
          justify-content: center;

          &_active {
            background-color: transparent !important;
            border: var(--main-color) 2px solid !important;
          }

          &_content {
            color: #FFFFFF;
            font-family: 'Poppins', sans-serif;
            font-size: 17px;
            letter-spacing: 0;
            line-height: 24px;
            text-align: center;
            display: flex;
            align-items: center;

            &_active {
              color: var(--main-color);
              -webkit-text-fill-color: var(--main-color);
            }

            svg {
              margin-right: 5px;
            }
          }
        }

        &_helper_text {
          color: #FFFFFF;
          font-size: 12px;

          &_active {
            visibility: hidden;
          }
        }

      }


      &_prices {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        &_current-price {
          color: #08B273;
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          letter-spacing: 0;
          line-height: 29px;
        }
        &_full_price {
          opacity: 0.4;
          color: #FFFFFF;
          font-family: 'Poppins', sans-serif;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 29px;
        }
      }

      &_title {
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        font-size: 21px;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 10px;
      }

      &_showroom-btn {
        height: 40px !important;
        width: 160px !important;
        border-radius: 100px !important;
        background-color: #000000 !important;
        margin-bottom: 26px !important;
        display: flex;
        justify-content: center;
        &_content {
          color: #FFFFFF;
          font-family: 'Poppins', sans-serif;
          font-size: 17px;
          letter-spacing: 0;
          line-height: 24px;
          text-align: center;
          display: flex;
          align-items: center;
        }
      }

      &_description {
        font-family: 'Poppins', sans-serif;
        opacity: 0.64;
        color: #FFFFFF;
        -webkit-text-fill-color: #FFFEFE;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 23px;
        height: 70%;
        overflow-y: auto;
        width: 100%;

        & * {
          all: revert;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
            font-size: 18px;
        }
      }
    }

    &_gallery {
      min-width: 300px;
      max-width: 55%;
      margin-left: 40px;
      margin-right: 40px;
      display: flex;
      flex-direction: column;
      flex: 2;
      align-items: center;

      &_dots {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
      }

      &_block {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        align-self: center;

        &_left-arrow {
          margin-right: 40px;
          margin-bottom: 15px;
          cursor: pointer;
        }

        &_right-arrow {
          margin-left: 40px;
          margin-bottom: 15px;
          cursor: pointer;
        }

        &_images-gallery {
          display: grid;
          gap: 10px;
          grid-auto-flow: column;
          width: 190px;
          overflow-x: auto;
          margin-bottom: 10px;
          padding-bottom: 10px;
          align-self: center;

          &_previews-wrapper {
            position: relative;
            height: 81px;
            width: 55px;
            border-radius: 8px;
            overflow: hidden;

            &:before {
              content: '';
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: 3px solid var(--main-color);
              border-radius: 8px;
              opacity: 0;
              will-change: opacity;
              transition: .14s opacity;
            }

            &.highlighted {
              &:before {
                opacity: 1;
              }
            }
          }

          &_previews {
            height: 100%;
            width: 100%;
            background-color: #D8D8D8;
            object-fit: contain;
            cursor: pointer;
          }
        }
      }

      &_images-gallery::-webkit-scrollbar {
        display: none;
      }

      &_main-image {
        width: auto;
        height: auto;
        object-fit: contain;
        flex: 2;

        &_default {
          visibility: hidden;
        }
      }

      &_zoom {
        display: flex;
        flex: 2;
        align-items: center;
        justify-content: center;
      }
    }

    &_right-part {
      width: 70px;
      position: absolute;
      right: 0;

      &_close-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        height: 33px;
        width: 33px;
        border: 1px solid #FFFEFE;
        opacity: 0.75;
        border-radius: 16px;
      }
    }

    .price-container {
      :nth-child(2) {
        color: rgba(255, 255, 255, 0.4);
        -webkit-text-fill-color: rgba(255, 255, 255, 0.4);
        text-decoration: line-through;
      }
    }
  }
}

.border-white {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 6px;
  height: 12px;
  width: 12px;
  margin-left: 3px;
  margin-right: 3px;
}

.border-green {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-color);
  border-radius: 6px;
  height: 12px;
  width: 12px;
}

.body-gray {
  background-color: rgba(0,0,0,0.8);
  border-radius: 4px;
  height: 8px;
  width: 8px;
}

.body-white {
  background-color: white;
  border-radius: 4px;
  height: 8px;
  width: 8px;
}
