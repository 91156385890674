.showroom-preset-item-header {
  background-color: #F5F5F5;
  display: flex;
  margin-bottom: 2px;
  cursor: pointer;
  align-items: center;
  padding-right: 12px;

  .showroom-preset-item-title {
    display: grid;
    padding: 12px 16px;
    width: 100%;

    .preset-title {
      color: var(--base-font-color);
      -webkit-text-fill-color: var(--base-font-color);
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .subtitle {
      color: var(--main-color);
      -webkit-text-fill-color: var(--main-color);
      font-size: 12px;
    }
  }

  .remove-preset {
    justify-self: center;
    cursor: pointer;
    height: 100%;
    align-items: center;
    display: flex;
    margin-right: 12px;

    &.disabled {
      cursor: default;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

}