.draft-order-custom-product {
  grid-column-start: 1;
  grid-column-end: 3;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;

  .custom-product-description-container {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .section {
    display: grid;
    grid-row: 2;
    grid-template-rows: 20px auto;
    margin-top: 8px;

    p {
      color: gray;
    }

    input[type='number'] {
      -moz-appearance:textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  .input-symbol {
    position: relative;
  }

  .input-symbol:after {
    position: absolute;
    top: 0;
    right: 8px;
    margin-top: 14px;
    content: attr(symbol);
  }

  .custom-product-input {
    box-sizing: border-box;
    border: 1px solid #CCC3BD;
    border-radius: 8px;
    margin-top: 8px;
    padding-left: 10px;
    background: #FFF;
    height: 2.2em;
    width: 100%;

    &.invoice-custom-message {
      height: 120px;
      padding: 4px;
    }

    &:disabled {
      color: gray;
    }
  }

  .custom-product-buttons {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-self: center;
    margin: 0 0 20px 50%;
  }

}