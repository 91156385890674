.customer-search-panel-container {
    display: grid;
    grid-auto-flow: row;
    gap: 14px;
    width: 100%;
    height: 100%;
    grid-template-rows: max-content;
    align-content: flex-start;

    .customer-search-panel-header {
        display: flex;
        grid-auto-flow: column;
        grid-gap: 14px;
        gap: 14px;
        justify-content: flex-start;
        width: 100%;
        position: relative;

        .customer-search-panel-back-button {
            display: flex;
            justify-content: center;
            align-items: center;
            transition: .14s opacity;
            will-change: opacity;

            &:hover {
                opacity: .4;
            }
        }

        .customer-search-panel-label {}
    }

    .customer-search-container {
        .empty {
            opacity: .6;
        }
    }
}
