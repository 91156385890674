.client-history-container {
  display: flex;
  height: 100%;
  overflow: hidden;

  .empty-container {
    flex-wrap: wrap;
    margin: 15%;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: gray;
  }

  .client-history-wrapper {
    margin-top: 64px;
    width: 100%;
    display: flex;
    flex-flow: row;
    overflow: hidden;
    height: calc(100% - 68px);

    .client-history-client-details {
      width: 450px;
      height: 100%;
      border-right: 1px solid #ccc3bd;

      .client-details-tab {
        position: static;
      }

      .customer-name {
        font-size: 20px;
        font-weight: bold;
        color: #3F3F3F;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .customer-email {
        color: #66676C;
        font-size: 14px;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        margin-right: 6px;
        flex-wrap: nowrap;
        display: block;
        flex: 1 1;
      }
    }
  }

  .call-details-sidebar-container {
    margin-top: 64px;
    height: calc(100% - 64px);
  }

  .showroom-presets-modal {
    margin-top: 64px;
    height: calc(100% - 64px);
  }
}



