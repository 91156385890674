.sidebar-product-details-tab {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0 16px;
  margin-top: 16px;
  overflow-y: auto;

  &.visible {
    visibility: visible;
    z-index: 1;
  }
}