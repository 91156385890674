.call-outcome {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 12.5px;
  padding: 1px 10px;
  font-size: 12px;
  position: relative;

  .overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &.success {
    background-color: #C8E4E0;
    color: #5F7874;
    -webkit-text-fill-color: #5F7874;
  }

  &.checkout-started {
    background-color: #FFE5D0;
    color: #CF7B35;
    -webkit-text-fill-color: #CF7B35;
  }

  &.error {
    background-color: #FFD7D7;
    color: #C64646;
    -webkit-text-fill-color: #C64646;
  }

  &.abandoned {
    background-color: #FFE5D0;
    color: #CF7B35;
    -webkit-text-fill-color: #CF7B35;
  }

  &.event-type {
    background-color: var(--badge-color);
    color: white;
    -webkit-text-fill-color: white;

    .event-type-text {
      -webkit-text-fill-color: var(--contrastColor, var(--base-font-color));
    }

    :first-child {
      margin-right: 0;
    }
  }

  :first-child {
    margin-right: 8px;
  }

  .alert-point-container {
    position: absolute;
    top: -20%;
    right: -14px;
    transform: translateY(-20%);
  }

  .draft-order-nowrap {
    white-space: nowrap;
  }
}

.call-outcome-scheduled-call {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: space-between;
  border-radius: 12.5px;
  padding: 1px 10px;
  font-size: 12px;
  position: relative;
  background: #BDC4FF;
  color: #4D53AD;
  -webkit-text-fill-color: #4D53AD;

}
