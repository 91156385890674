.chat-details-tab {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.visible {
    visibility: visible;
    z-index: 1;
  }
}