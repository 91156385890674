.products-suggection-section {
    display: flex;
    flex-flow: column;

    .tabs-container {
        display: flex;
        flex-flow: row nowrap;
        margin-left: -1ch;

        .tab-item {
            opacity: .6;
            will-change: opacity;
            transition: .25s opacity;
            padding: 0 1ch;
            cursor: pointer;

            &.active {
                opacity: 1;
            }
        }
    }

    .products-suggection-container {
        overflow-x: auto;
        height: calc(100% - 24px);

        .product-view {
            display: flex;
            height: 100%;

            .image-with-overlay {
                width: calc(20vh * 0.66);
                min-width: calc(20vh * 0.66);
                height: 20vh;
                margin: 1vh;
            }

            .image-with-overlay-no-click {
                width: calc(15vh * 0.66);
                min-width: calc(15vh * 0.66);
                height: 15vh;
                margin: 1vh;
            }
        }
    }
}
