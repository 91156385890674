.homepage-container {
  height: calc(100% - 88px);
  border-radius: 32px 32px 0 0;
  background: white;
  display: grid;
  position: relative;

  &.common {
    grid-template-columns: unset;
  }

  &.grid {
    grid-template-columns: 320px auto;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    z-index: -1;
  }

  aside {
    display: flex;
    flex-flow: column;
    height: 100%;
    border-right: 1px solid #ccc3bd;
    width: 320px;
    hr {
      border: 1px solid rgba(151, 151, 151, 0.4);
      width: 320px;
      margin-bottom: 0;
    }
    .sidebar-header {
      background-color: var(--peach-color-60);
      border-top-left-radius: 30px;

      .react-calendar__navigation {
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;

        .react-calendar__navigation__prev-button {
          margin-left: 5px;
        }

        .react-calendar__navigation__next-button {
          margin-right: 5px;
        }
      }
    }
  }
  .call-loader {
    grid-column: 1/3;
  }
  .calendar-container {
    overflow: hidden;
    height: 100%;
    display: grid;
    grid-auto-flow: row;
    grid-template-rows: auto 1fr;

    .calendar-wrapper {
      overflow: hidden;
      grid-auto-flow: column;
      grid-template-columns: 1fr auto;
      display: grid;
      width: 100%;
    }
  }
  .home-page-row {
    margin-top: 1rem;
  }
  .calendar-divider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      font-size: 14px;
      background-color: #565656;
      color: white;
      -webkit-text-fill-color: white;
      width: 76px;
      border-radius: 4px;
      text-align: center;
    }
    div {
      width: 100%;
      height: 2px;
      background-color: #565656;
    }
    @media (max-width: 1024px) {
      margin: 24px auto;
    }
  }
  .empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ccc3bd;
    padding-top: 30%;
    h1 {
      color: #ccc3bd;
      -webkit-text-fill-color: #ccc3bd;
      text-align: center;
    }
    div {
      color: black;
    }
  }
}

.extra-container {
  display: flex;
  width: 20vw;
  justify-content: space-between;
  align-items: center;
}

.ant-notification-notice-btn {
  width: 40%;
  display: flex;
  justify-content: space-between;
}

.call-row-regular {
  background-color: #f7f7f7;
  color: #000;
  -webkit-text-fill-color: #000;
  opacity: 0.64;
}

.call-row-error {
  background-color: #fff2f0;
  color: #000;
  -webkit-text-fill-color: #000;
  opacity: 0.64;
}

.call-row-order {
  background-color: #f6ffed;
  color: #000;
  -webkit-text-fill-color: #000;
  opacity: 0.64;
}

.call-row-schedule {
  color: #000;
  -webkit-text-fill-color: #000;
}

.schedule-row {
  background-color: #fff3e0;
}

.schedule-row-urgent {
  background-color: #fff2f0;
}

.navbar-page-container {
  background-color: var(--midnight-color);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.calendar {
  padding: 10px;
  height: 235px;
  .react-calendar__tile {
    color: gray;
    padding-top: 2px;
  }
  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: none;
    }
  }
  .react-calendar__navigation__label__labelText {
    font-weight: 600;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: rgba(151, 151, 151, 0.4);
  }
  .react-calendar__month-view__weekdays__weekday {
    color: rgba(151, 151, 151, 0.4);
    font-size: 14px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .react-calendar__tile--now {
    font-weight: 700;
    color: black;
  }
  .react-calendar__navigation__next-button {
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 0.4);
    border-radius: 15px;
    width: 30px;
    height: 30px;
    margin-right: 30px;
    padding-top: 3px;
  }
  .react-calendar__navigation__prev-button {
    background-color: white;
    border: 1px solid rgba(151, 151, 151, 0.4);
    border-radius: 15px;
    width: 30px;
    height: 30px;
    margin-left: 30px;
    padding-top: 3px;
  }
}

.style-one-symbol {
  .react-calendar__tile--active {
    abbr {
      background-color: var(--sherbert-color);
      color: white;
      padding-right: 10px;
      padding-left: 10px;
      border-radius: 15px;
    }
  }
}

.style-two-symbols {
  .react-calendar__tile--active {
    abbr {
      background-color: var(--sherbert-color);
      color: white;
      padding-right: 5px;
      padding-left: 5px;
      border-radius: 15px;
    }
  }
}

.icon-align {
  vertical-align: text-bottom;
}

.content-header {
  flex-direction: column;

  hr {
    border: 1px solid rgba(151, 151, 151, 0.4);
    width: 100%;
    margin: 0;
  }
}

.row-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px 15px 25px;
}

.empty-page {
  display: flex;
  flex-direction: column;
}

.calls-items-list {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 35px 48px 12px 48px;
  width: 100%;
  max-width: 1024px;
  justify-self: center;
  overflow-y: auto;
}

.calls-items-list::-webkit-scrollbar {
  display: none;
}
