.list-calls-skeleton-container {
  display: flex;
  align-items: center;
  flex-flow: column;

  .skeleton-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    width: 100%;
  }

  hr {
    border: 1px solid hsla(0,0%,59.2%,.4);
    width: 100%;
    margin: 0;
  }

}
