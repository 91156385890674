.call-details-sidebar-container {
  height: 100%;
  max-width: 450px;
  width: 100vw;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  visibility: visible;
  display: flex;
  flex-flow: column;
  overflow: hidden;
  justify-content: flex-start;

  .badge-row {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    margin: 6px 16px 0 16px;
    justify-content: space-between;

    .cart-details-action-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      grid-column-end: 3;

      p {
        color: var(--main-color);
        font-size: 14px;
        margin-left: 4px;
      }

      &.disabled {
        cursor: default;

        p {
          color: var(--light-font-color);
        }
      }
    }
  }

  .call-details-sidebar-wrapper {
    height: 100%;
    display: flex;
    flex-flow: column;

    &.scroll {
      overflow-y: auto;
      display: block;
    }
  }

  &.show {
    animation: side-bar-in 500ms forwards;
  }

  &.close {
    animation: side-bar-out 500ms forwards;
  }

  .call-details-sidebar-header {
    padding: 16px 16px 0 16px;
  }

  .call-duration-container {
    justify-content: space-between;
    display: flex;

    .close-call-details-side-bar-button {

    }
  }

  .call-details-error-component {
    display: flex;
    margin: 0 8px 0 0;;
    align-items: center;

    .error-call-outcome {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12.5px;
      padding: 1px 10px;
      font-size: 12px;
      position: relative;
      background-color: #FFD7D7;
      color: #C64646;
      -webkit-text-fill-color: #C64646;
    }
  }

  .customer-name {
    font-size: 20px;
    font-weight: bold;
    color: #3F3F3F;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .customer-info {
    color: #66676C;
    font-size: 14px;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;

    &.right {
      text-align: end;
    }

    &.email {
      margin-right: 6px;
      flex-wrap: nowrap;
      display: block;
      flex: 1 1;

      &.clickable {
        text-decoration: underline;
        color: blue;
        -webkit-text-fill-color: blue;
        cursor: pointer;
      }
    }
  }

  @keyframes side-bar-in {
    0% {
      width: 0;
      min-width: 0;
      transform: translateX(100%);
      visibility: visible;
    }
    100% {
      width: 100vw;
      min-width: 450px;
      transform: translateX(0);
      visibility: visible;
    }
  }

  @keyframes side-bar-out {
    0% {
      width: 100vw;
      min-width: 450px;;
      transform: translateX(0);
      visibility: visible;
    }
    100% {
      width: 0;
      min-width: 0;
      transform: translateX(100%);
      visibility: hidden;
    }
  }

  .call-modal-draft-order-modal-background {
    z-index: 1;

    &.visible {
      width: 100vw;
    }
  }

  .block-event-wrapper {
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-flow: column;
    margin: 16px;
    overflow: hidden;

    .description-wrapper {
      height: 100%;
      overflow: hidden;
      overflow-y: auto;
      margin-bottom: 12px;
    }
  }
}

