.invoice-modal-background {
  position: absolute;
  height: 100vh;
  width: 100vw;
  display: none;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);

  .invoice-modal {
    bottom: 0;
    right: 0;
    border-radius: 30px;
    background-color: white;
    border-left: #ccc 1px solid;
    min-width: 600px;
    max-width: 40vmax;
    width: 100%;
    min-height: 600px;
    max-height: 60vh;
    height: 100%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    padding: 0 20px;
    display: grid;
    grid-template-rows: 60px auto 90px;

    &.no-show {
      visibility: hidden;
    }

    .invoice-header {
      display: grid;
      align-items: center;
      margin: 20px 0;
      grid-template-columns: auto 40px;

      .close-button {
        box-sizing: border-box;
        height: 30px;
        width: 30px;
        border: 1px solid #999999;
        border-radius: 16px;
        background-color: #ffffff;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        margin-right: 20px;
      }
    }

    .invoice-container {
      overflow-y: auto;
      flex-direction: column;

      iframe {
        pointer-events: none;
      }

      .invoice-preview {
        width: 100%;
        height: 100%;
      }

      .invoice-modal-send-container {
        display: grid;
        grid-column-gap: 20px;
      }

      .invoice-modal-bcc {
        margin-top: 10px;
      }

      .section {
        display: grid;
        grid-row: 3;
        grid-template-rows: 20px auto 20px;

        p {
          color: gray;
        }

        span {
          color: red;
          font-size: 12px;
        }
      }

      .invoice-input {
        box-sizing: border-box;
        border: 1px solid #CCC3BD;
        border-radius: 8px;
        margin-top: 8px;
        padding-left: 10px;
        background: #FFF;
        height: 2.2em;

        &.invoice-custom-message {
          height: 120px;
          padding: 4px;
        }

        &:disabled {
          color: gray;
        }
      }
    }

    .invoice-footer {
      display: grid;
      grid-template-rows: 15px auto;
      grid-template-columns: auto 110px 160px;
      align-items: center;
      justify-items: flex-end;

      span {
        color: red;
        font-size: 14px;
        justify-self: flex-start;
        grid-column-start: 1;
        grid-column-end: 4;
      }
    }
  }

  &.visible {
    display: block;
    z-index: 201;
  }
}

