.call-details-tabs-container {
  width: 100%;
  display: flex;
  flex-flow: column;
  flex: 1;

  &.info {
    height: 100%;
    flex: none;
  }

  .tabs-wrapper {
    position: relative;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}