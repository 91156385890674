.device-selector {
	
	&__control {
		box-sizing: border-box;
		border: 1px solid #ccc3bd;
		border-radius: var(--border-radius-m);
		height: 40px;
		font-size: 14px;
		max-width: 400px;
		flex-flow: row nowrap !important;
		display: flex;
	}
	&__menu {
		border-radius: var(--border-radius-xs);
	}
}
