.empty-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ccc3bd;
  padding-top: 30%;
  h1 {
    color: #ccc3bd;
    -webkit-text-fill-color: #ccc3bd;
    text-align: center;
  }
}

.container {
  height: 100%;
  display: flex;
  flex-flow: column;
  position: static;
  overflow: hidden;
}
