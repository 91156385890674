.call-summary-tab {
  visibility: hidden;
  position: absolute;
  transform: translateX(450px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;

  .call-details-error-component {
    display: flex;
    margin: 8px;
    align-items: center;

    .error-message {
      margin-left: 6px;
      font-size: 14px;
      color: #3f3f3f;
    }

    .error-call-outcome {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 12.5px;
      padding: 1px 10px;
      font-size: 12px;
      position: relative;
      background-color: #FFD7D7;
      color: #C64646;
      -webkit-text-fill-color: #C64646;
    }
  }

  .call-showroom-tab {
    position: relative;
    overflow-y: visible;
    height: auto;
  }

  .form-responses-tab {
    position: relative;
    overflow-y: visible;
    height: auto;
  }

  .call-details-feedback-section {
    align-self: flex-start;
    display: flex;
    align-items: flex-start;

    .feedback-message-container {
      display: flex;
      justify-content: flex-start;
      padding: 0 0 0 14px;
      align-items: center;
      flex-flow: column;

      .feedback-message {
        font-size: 14px;

        .rating-stars-container {
          display: inline;

          .rating-value {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.visible {
    visibility: visible;
    transform: translateX(0px);
    z-index: 1;
  }

}