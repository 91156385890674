.loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.loader {
  border-radius: 50%;
  box-sizing: border-box;
  border: 8px solid white;
  border-bottom: 8px solid var(--main-color);
  border-right: 8px solid var(--main-color);
  border-top: 8px solid var(--main-color);
  animation: spin 2s linear infinite;
  margin: 1em;

  &.stop {
    animation-play-state: paused;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
