.empty-image {
  border: lightgrey solid 1px;
  border-radius: 16px;
  background-color: whitesmoke;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 100%;
  text-align: center;

  p {
    width: 80%;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &.highlighted {
    border: 3px solid var(--main-color);
  }
  
  &.s-size {
    height: 120px;
    width: 80px;
  }

  &.m-size {
    height: 140px;
    width: 96px;
  }

  &.l-size {
    height: 180px;
    width: 120px;
  }

  &.xl-size {
    height: 150px;
    width: 150px;
  }

  &.xs-size {
    height: 80px;
    width: 64px;
  }

  .camera-alt-icon {
    &.s-size {
      font-size: 25px;
    }

    &.m-size {
      font-size: 40px;
    }

    &.l-size {
      font-size: 50px;
    }

    &.xl-size {
      font-size: 75px;
    }
  }
}
