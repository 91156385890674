.selected-product-variant-container {
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 10px;
  padding: 2px 10px;

  .selected-product-variant-item {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;

    .selected-product-variant-item-description {
      display: flex;
      .main-image {
        border-radius: 10px;
        object-fit: contain;
        height: 80px;
        max-width: 60px;
      }

      .product-description {
        margin-left: 20px;

        span {
          display: flex;
        }

        .product-title {
          font-weight: bold;
        }

        .variant-title {
          font-weight: unset;
          font-size: 14px;
          color: grey;
        }

        .price-container {
          cursor: pointer;
        }
      }
    }

    .selected-product-variant-actions {
      display: grid;
      padding: 4px 0;

      .quantity-input {
        display: flex;
        height: 22px;
        padding: 0 20px;

        * {
          border: 1px solid #cfc8bb;
          box-sizing: border-box;
        }
        input::-webkit-inner-spin-button,
        input::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        button {
          font-weight: 600;
          color: #cfc8bb;
          -webkit-text-fill-color: #cfc8bb;
          font-size: 16px;
          line-height: 18px;
          width: 22px;
          background: #FFF;

          &:nth-child(1) {
            border-radius: 8px 0 0 8px;
          }
          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }
          &:disabled {
            opacity: .4;
          }
        }
        input {
          display: inline-block;
          vertical-align: top;
          max-width: 30px;
          text-align: center;
          background: #FFF;
        }
      }

      .remove-selected-product-product {
        align-self: flex-end;
        justify-self: center;
        cursor: pointer;

        svg {
          width: 20px;
          height: 20px;
        }

        .prefix__active-path {
          fill: #be1919;
        }
      }
    }
  }

  .selected-product-variant-discount {
    padding: 0 10px;
  }
}

