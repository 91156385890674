.tooltip-container {
   position: absolute;
   z-index: -100;
   top: 50%;
   left: 50%;
   opacity: 0;
   transform: scale(0) translate3d(-50%, -50%, 0);
   transition: .3s;

   &.active {
      z-index: 3;
      transform-origin: 50% 50%;
      transform: scale(1) translate3d(-50%, -50%, 0);
      opacity: 1;
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.175);
      animation-duration: .3s;
      animation-name: show;
      animation-iteration-count: 1;
   }

   .tooltip {
      width: 300px;
      background: #f0f0f1;
      border-radius: 8px;
      position: relative;
      box-shadow: 0px 0px 17px #00000070;
      border: 1px solid rgba(0, 0, 0, .3);

      .content {
         display: flex;
         flex-flow: column;
         list-style: none;
         margin: 0;
         padding: 0;
         position: relative;

         .btn-close-icon {
            position: absolute;
            top: 0;
            right: 0;
            padding: 16px;
            display: flex;
         }

         .row {
            padding: .7em 1em;

            &:last-child() {
               border-bottom: 1px solid rgba(0, 0, 0, .1);
            }

            .title {
               font-size: 16px;
               font-weight: 500;
            }

            &.properties {
               border-top: 1px solid #bdbdbd;
               border-bottom: 1px solid #bdbdbd;
               display: grid;
               font-size: 14px;

               .label {
                  font-size: 14px;
               }

               input {
                  appearance: auto;
                  width: 100%;
               }
            }
         }

         .actions {
            display: grid;
            padding: 0 1em .7em 1em;

            .actions-message-container {
               grid-row: 1;
            }

            .actions-buttons-container {
               grid-row: 2;
               display: flex;
               flex-flow: row nowrap;
               justify-content: space-between;
               align-items: flex-end;
               margin: 1ch 0 0 0;

               .confirm-change-recurrence-dialog {
                  display: flex;
                  flex-flow: column;
               
                  .confirnation-dialog-title {
                     font-size: 14px;
                  }
               
                  .confirnation-dialog-title-context {
                     font-size: 14px;
                     opacity: .6;
                     margin-bottom: 1em;
                  }
               
                  .confirnation-dialog-actions {
                     display: flex;
                     flex-flow: row nowrap;
                     justify-content: space-between;
               
                     .slot-actions-activity {
                        display: flex;
                        flex-flow: row nowrap;
                     }
                  }
               }

               .btn {
                  font-size: 14px;
                  font-weight: 500;
                  border-radius: 14px;
                  background: #ffffff;
                  display: flex;
                  border: 1px solid rgba(0, 0, 0, .3);
                  padding: .4em .6em;
                  display: inline-block;
                  text-align: center;
                  vertical-align: middle;    
                  min-width: 70px;

                  &:disabled {
                     opacity: .4;
                  }
               }

               .actions-group {
                  display: flex;
                  flex-grow: 1;
                  flex-flow: row;
                  justify-content: space-between;

                  .btn {
                     margin: 0 1em 0 0;

                     &:last-child {
                        margin: 0;
                     }
                  }
               }

               .delete-actions-group {
                  display: flex;
                  flex-flow: column;
                  flex-grow: 1;

                  .btn {
                     margin: 0 0 1em 0;

                     &:last-child {
                        margin: 0;
                     }
                  }

                  &.regular {
                     align-items: flex-end;
                  }
               }
            }
         }
      }
   }
}

@keyframes show {
   0% {
      transform: scale(0) translate3d(-50%, -50%, 0);
      opacity: 0;
   }

   100% {
      opacity: 1;
      transform: scale(1) translate3d(-50%, -50%, 0);
   }
}

.react-datepicker-popper {
   &[data-placement^="bottom"] .react-datepicker__triangle {
      margin-top: -7px !important;
   }
   
   &[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
      border-bottom-color: white !important;
   }

   .react-datepicker {
      display: flex;
      flex-flow: row nowrap;

      .react-datepicker__month-container {
         .react-datepicker__header {
            background-color: transparent;
            border-bottom: none;

            .react-datepicker__current-month {
               font-size: 14px;
               border-bottom: 1px solid #bdbdbd;
               padding-bottom: 8px;
            }

            .react-datepicker__day-names {
               .react-datepicker__day-name {
                  font-size: 12px;
               }
            }
         }

         .react-datepicker__month {
            .react-datepicker__week {
               .react-datepicker__day--keyboard-selected {
                  background-color: var(--main-color);
               }

               .react-datepicker__day--selected {
                  background-color: transparent;
                  color: var(--main-color);
               }
            }
         }
      }

      .react-datepicker__time-container  {
         width: 100px;

         .react-datepicker__header {
            background-color: transparent;
            border-bottom: none;
            border-bottom: 1px solid #bdbdbd;

            .react-datepicker__current-month {
            }

            &.react-datepicker__header--time {
               .react-datepicker-time__header {
                  font-size: 14px;
               }
            }
         }

         .react-datepicker__time {
            .react-datepicker__time-box {
               width: 100px;

               ul.react-datepicker__time-list li.react-datepicker__time-list-item {
                  font-size: 14px;
               }

               ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
                  background-color: var(--main-color);
               }
            }
         }
      }
   }
}
