.availability-legend-container {
  flex-flow: row;
  display: flex;
  margin-right: 22px;

  .colored-block {
    height: 20px;
    width: 40px;
    margin-right: 8px;
    background-color: var(--block-color);
  }
}