.draft-order-modal {
  z-index: 101;
  background-color: white;
  border-left: #ccc 1px solid;
  min-width: 450px;
  max-width: 100%;
  width: 100%;
  min-height: 600px;
  height: 100%;
  position: fixed;
  top: 0;
  display: grid;
  grid-template-rows: 60px auto 90px;
  visibility: hidden;

  &.active-call {
    max-width: 37.5vw;
  }

  &.open {
    animation: bounce-in 0.5s forwards;
    visibility: visible;
  }

  &.close {
    animation: bounce-out 0.5s forwards;
    visibility: visible;
  }

  &.margin {
    height: calc(100% - 6px);
    margin: 3px;
  }

  @keyframes bounce-in{
    0% { right: -37.5vw; }
    100% { right: 0; }
  }
  @keyframes bounce-out{
    0% { right: 0; }
    100% { right: -37.5vw; }
  }

  .draft-order-header {
    display: grid;
    grid-template-columns: auto 140px 40px;
    padding: 0 20px;
    align-items: center;
    box-shadow: 0 1px 10px rgba(0,0,0,0.1);

    .close-button {
      box-sizing: border-box;
      height: 30px;
      width: 30px;
      border: 1px solid #999999;
      border-radius: 16px;
      background-color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-right: 20px;
    }

    .draft-order-completed-tag {
      background: var(--main-color);
      display: flex;
      width: 120px;
      align-items: center;
      justify-content: center;
      padding: 4px 0;
      margin-left: 20px;
      border-radius: 30px;

      .draft-order-completed-title {
        color: white;
        font-size: 16px;
      }
    }
  }

  .draft-order-container {
    overflow-y: auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
  }

  .draft-order-notes {
    margin-bottom: 10px;
  }

  .draft-order-footer {
    padding: 0 20px;
    box-shadow:
            inset 0 -1px 10px rgba(0,0,0,0.1),
            0 -1px 1px rgba(0,0,0,0.1);

    .draft-order-action-buttons {
      display: flex;
      justify-self: flex-end;
      width: 100%;
      justify-content: flex-end;
      align-self: center;
      padding-top: 20px;
      &.with-remove-button {
        justify-content: space-between;
      }
      &.with-message {
        padding-top: 0;
      }
    }
  }

}
