.settings-section {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1em .4em;

  .section-icon {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
  }

  .section-title {
    grid-column: 2;
    grid-row: 1;
  }

  .settings-container {
    display: grid;
    grid-column: 1/3;
    gap: 1em .4em;
    margin-left: .2em;

    .test-description {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }

    .test-camera-notice {
      font-size: smaller;
    }

    .test-running-notice {
      display: flex;
      flex-direction: column;
    }

    .test-running-error {
      color: var(--error-color);
    }

    .section-settings-title {
      &:not(:first-child) {
        margin-top: 2em;
      }
    }

    span {
      display: flex;
      flex: 1;
      flex-direction: row;
      align-items: center;

      p {
        margin: 0 !important;
      }
    }

    .manage-device-controls {
      display: flex;
      gap: 0 1em;
      flex-wrap: nowrap;
      align-items: center;

      div {
        &:first-child {
          flex: 0 1 400px;
        }
      }

      video {
        width: 320px;
        height: 240px;
      }

      .test-button {
        display: grid;
        gap: 0 1em;
        grid-auto-flow: column;
        grid-template-columns: auto auto;
        will-change: opacity;
        transition: .25s opacity;

        .test-button-label {
          position: relative;
          overflow: hidden;
          display: flex;
          width: 100px;
          user-select: none;

          .passive-state {
            transform: translate3d(0, 0, 0);
            transition: .25s ease-in-out transform;
            will-change: transform;
          }

          .active-state {
            transform: translate3d(0, -100%, 0);
            transition: .25s ease-in-out transform;
            will-change: transform;
            left: 0;
            position: absolute;
          }
        }

        &.playing {
          opacity: .4;

          .test-button-label {
            .passive-state {
              transform: translate3d(0, 100%, 0);
              transition: .14s ease-in-out transform;
            }

            .active-state {
              transform: translate3d(0, 0, 0);
              transition: .14s ease-in-out transform;

            }
          }
        }


        &:hover {
          opacity: .4;
        }
      }


      &.video-settings {
        align-items: flex-start;
        display: grid;
        grid-auto-flow: row;
        gap: 1em;

        .device-selector__control,
        .device-selector__menu {
          width: 100%;
          max-width: 400px;
          flex: auto;

        }



        .video-element-container {
          max-height: 240px;
          width: 320px;
          position: relative;
        }
      }



    }
    /*  .device-selector {
         max-width: 400px;
     } */
  }

}

.start-diagnostic-button {
  font-weight: 500;
  border-radius: 4px;
  height: auto;
  outline: none;
  box-sizing: border-box;
  border: 1px solid;
  margin: 0 1em;
  width: 160px;
  height: 44px;

  .spinner {
    align-self: center;
    display: inline;
  }

  &:disabled {
    background-color: var(--light-font-color) !important;
  }
}


.fade-in {
  opacity: 1 !important;
  transition: opacity 1s ease;
}

.diagnostic-result {
  opacity: 0;
  display: flex;
  flex: 1;
  flex-direction: row;

  &_date {
    font-weight: 500;
    font-size: 14px;
    color: var(--light-font-color);
    opacity: 0;
  }

  &_hidden {
    display: none;
  }

  &_error {
    opacity: 0;
  }

  &_key {
    font-weight: 500;
  }

  &_value {
    margin-left: 20px;

    &_error {
      margin-left: 20px;
      color: var(--error-color);
    }
  }
}
