.message-item {
  padding: 0px 10px;

  &_hidden_message {
    display: none;
  }

  &_my {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    p {
      opacity: 0.64;
      color: #000000;
      font-family: 'Jost', sans-serif;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 23px;
      margin-left: 10px;
      cursor: pointer;
    }

    &_info {
      font-weight: 500 !important;
    }

    &_invitation {
      cursor: default !important;
    }

    &_question {
      font-weight: 500 !important;
      cursor: default !important;
      margin-right: 10px;
    }
  }

  &_system {
    align-items: center;
    justify-content: center;
    color: var(--main-color);
    -webkit-text-fill-color: var(--main-color);
    font-size: 16px;
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;

    &_delimiter {
      height: 1px;
      background-color: var(--main-color);
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      flex: 2;
    }
  }

  &_newmessage {
    align-items: center;
    justify-content: center;
    color: var(--sherbert-color);
    -webkit-text-fill-color: var(--sherbert-color);
    font-size: 16px;
    display: flex;
    flex-direction: row;
    text-align: center;
    width: 100%;
    padding: 0px;

    &_delimiter {
      height: 1px;
      background-color: var(--sherbert-color);
      width: 100%;
      margin-left: 10px;
      margin-right: 10px;
      padding: 0px;
      flex: 2;
    }
  }

  &_newmessagehide {
    margin-top: 26px;
    margin-bottom: 10px;
  }

  .time {
    padding-left: 60px;
    color: var(--base-font-color);
    font-size: 14px;
    margin-top: 5px;
  }
  .host-time {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    padding-right: 10px;
    color: var(--base-font-color);
    font-size: 14px;
    margin-top: 5px;
  }

  .msg-with-image {
    display: flex;
    flex-direction: row;

    .image-system-border {
      border: var(--main-color) 1px solid;
      height: 25px;
      width: 25px;
      border-radius: 13px;
    }

    .image-border {
      border: #ccc 1px solid;
      height: 40px;
      width: 40px;
      border-radius: 25px;
      text-align: center;

      .avatar {
        height: 38px;
        width: 38px;
        border-radius: 19px;
      }

      h4 {
        color: var(--base-font-color);
        font-size: 24px;
      }
    }
    .msg-border {
      border: #ccc 1px solid;
      background-color: #F7F7F7;
      max-width: 70%;
      border-radius: 10px;
      padding: 10px;
      margin-left: 20px;
      h5 {
        font-size: 18px;
        color: var(--base-font-color);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-break: break-word;
      }
      a {
        font-size: 18px;
        color: var(--base-font-color);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }
  }

  .host-msg-with-image {
    display: flex;
    flex-direction: row;
    align-self: flex-end;
    justify-content: flex-end;

    .host-image-border {
      border: #ccc 1px solid;
      height: 40px;
      width: 40px;
      border-radius: 25px;
      text-align: center;

      .avatar {
        height: 38px;
        width: 38px;
        border-radius: 19px;
      }

      h4 {
        color: var(--base-font-color);
        font-size: 24px;
      }
    }
    .host-msg-border {
      border: #ccc 1px solid;
      background-color: var(--main-color);
      max-width: 70%;
      border-radius: 10px;
      padding: 10px;
      margin-right: 10px;
      h5 {
        font-size: 18px;
        color: white;
        -webkit-text-fill-color: white;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-break: break-word;
      }
      a {
        font-size: 18px;
        color: white;
        -webkit-text-fill-color: white;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-break: break-word;
      }
    }
  }
}
