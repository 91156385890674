.google-icon {
    margin-right: 5px;
    background-color: '#ffffff';
    padding: 10px 10px 4px;
    border-radius: 2px;
}

.active-google-auth {
    background-color: '#eeeeee';
}
