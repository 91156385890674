.cart-component-wrapper {
  display: flex;
  width: 100%;
  flex: 1;
  flex-flow: column;
  padding: 0 12px;
  overflow-y: auto;

  .cart-component-details-action-row {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    padding-bottom: 6px;
    align-items: center;

    p {
      color: var(--main-color);
      font-size: 14px;
      margin-left: 4px;
    }
  }

  .cart-component-products-wrapper {
    overflow-y: auto;
    display: flex;
    flex-flow: column;
    flex: 1 1;
  }

  .cart-component-products-counter {
    font-size: 12px;
    margin-left: 4px;
    .limit {
      color: var(--error-color);  
    }    
  }

  .search-input-dropdown-field-overlay {
    padding-bottom: 12px;

    .search-input-dropdown-field-input {
      margin-top: 0;
    }

    .search-input-dropdown-field-result {
      max-height: 300px;
    }
  }
}
