.pending-call-shop-name {
    font-size: 11px;
    color: #66676C;
    -webkit-text-fill-color: #66676C;
}
.pending-call-name-wrapper {
    display: flex;
    align-items: center;

    .customer {
        display: block;
        align-items: center;
        font-size: 16px !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        flex: 0 1 auto;
    }

    .pending-event-type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 12.5px;
        padding: 1px 10px;
        font-size: 12px;
        position: relative;
        background-color: var(--badge-color);
        margin: 0 12px;

        .event-type-text {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            -webkit-text-fill-color: var(--contrastColor, var(--base-font-color));
            flex: 1 1 auto;
        }
    }
}
