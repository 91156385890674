.video-room-panel {
  display: flex;
  overflow: hidden;
  height: 100%;
  position: relative;
  flex-direction: column;

  &.dragged-over {
    background-color: rgb(240, 240, 240);
  }

  .product-row-wrapper {
    margin-left: 40px;
    overflow-x: auto;
    display: flex;

    .product-row {
      display: flex;
      padding: 24px 0px 10px 0;
      flex-flow: row nowrap;

      .product-container {
        position: relative;
        height: 180px;
        width: 120px;
        min-width: 120px;
        border-radius: 16px;
        margin-right: 10px;

        .imag-with-overlay {
          height: 100%;
          width: 100%;
        }

        .product-image {
          object-fit: contain;
          width: 100%;
          height: 100%;
          border-radius: 16px;
        }
      }
    }
  }


  .empty-showroom {
    width: 100%;
    height: calc(100% - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .empty-showroom p {
    width: 360px;
  }


  .product-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 560px);
    grid-template-rows: 164px;
    column-gap: 40px;
    row-gap: 24px;
    height: 428px;
    max-height: 460px;
    overflow-y: scroll;

    .product-card {
      display: grid;
      grid-template-columns: 96px 360px;
      column-gap: 24px;
      padding: 0 40px;
      align-items: center;
      .product-card-image {
        position: relative;
        width: 96px;
        height: 140px;
      }
      .product-card-details {
        height: 140px;
        width: 360px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        align-self: center;
        justify-content: space-between;
        span {
          color: black;
          -webkit-text-fill-color: black;
        }
        h5 {
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
          margin-top: unset;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow-y: hidden;
        }
        .price-container {
          display: flex;
          align-items: flex-end;
          flex-grow: 1;
          padding-bottom: 1ch;

          :nth-child(1) {
            color: var(--main-color);
            -webkit-text-fill-color: var(--main-color);
            margin-right: 8px;
          }
          :nth-child(2) {
            color: rgba(0, 0, 0, 0.4);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
            text-decoration: line-through;
          }
        }

        .product-params {
          font-size: 14px;
          display: grid;
          grid-auto-flow: column;
          gap: 0 16px;
          justify-content: flex-start;
          align-items: flex-start;
          grid-template-columns: auto 1fr auto;
          width: 100%;

          .quantity-input {
            display: flex;
            height: 22px;

            * {
              border: 1px solid #cfc8bb;
              box-sizing: border-box;
            }
            input::-webkit-inner-spin-button,
            input::-webkit-outer-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            button {
              font-weight: 600;
              color: #cfc8bb;
              -webkit-text-fill-color: #cfc8bb;
              font-size: 16px;
              line-height: 18px;
              width: 22px;

              &:nth-child(1) {
                border-radius: 8px 0 0 8px;
              }
              &:nth-child(3) {
                border-radius: 0 8px 8px 0;
              }
              &:disabled {
                opacity: .4;
              }
            }
            input {
              display: inline-block;
              vertical-align: top;
              max-width: 30px;
              text-align: center;
            }
          }

          .product-card-trash {
            align-self: flex-start;
            cursor: pointer;
            margin: 0 10px;

            svg {
              width: 20px;
              height: 20px;
            }

            .prefix__active-path {
              fill: #be1919;
            }
          }

          .selected-option {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }

  .post-purchase {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    h1 {
      font-size: 40px;
      font-weight: 600;
      color: black;
      -webkit-text-fill-color: black;
    }
  }

  .showroom-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    width: 100%;
    height: 230px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    h1 {
      color: white;
      -webkit-text-fill-color: white;
      font-size: 24px;
      font-weight: 600;
    }
    div {
      width: 30%;
      justify-content: space-evenly;
      display: flex;
    }
  }
}

.video-room-panel .cart-empty {
  height: calc(100% - 132px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-room-panel .cart-empty p {
  width: 360px;
}

.video-room-panel .actions {
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.video-room-panel .divider {
  border: 1px solid #ccc3bd;
  opacity: 0.4;
  width: 95%;
  margin: auto;
}

.video-room-panel .cart-footer {

  bottom: 0;
  width: 100%;
  background-color: white;

  .cart-footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 24px;
    height: 128px;

    h1 {
      font-size: 40px;
      line-height: 58px;
      margin-top: unset;
    }

    .start {
      display: flex;
      flex-direction: column;
      //padding-top: 24px;
    }

    .end {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row;
      margin-left: auto;
      padding-left: 8px;

      .discount {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-end;
        //height: 104px;

        .discount-actions {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
        }

        .discount-text {
          color: var(--main-color);
          -webkit-text-fill-color: var(--main-color);
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          margin-bottom: 4px;
        }

        .discount-error {
          color: var(--error-color);
          -webkit-text-fill-color: var(--error-color);
        }

      }

      .checkout-messages {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0px 8px 0px 16px;

        p {
          text-align: right;
        }
      }
    }
  }

  .discount-text {
    color: var(--main-color);
    -webkit-text-fill-color: var(--main-color);
  }
}