.image {
  display: flex;
  flex-direction: row;

  .icon {
    color: var(--base-font-color);
    margin-left: 5px;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

h6 {
  margin-top: 5px;
  font-size: 12px;
  color: var(--base-font-color);
  -webkit-text-fill-color: var(--base-font-color);
}
h5 {
  color: var(--base-font-color);
  -webkit-text-fill-color: var(--base-font-color);
}
