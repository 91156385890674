.select-event-type-container {
  margin-top: 20px;
  flex: 1 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  .event-types-wrapper {
    overflow-y: auto;
    height: 100%;
  }

  .event-actions-container {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
  }
}