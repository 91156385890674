.draft-order-payment {
  margin-top: 10px;

  .draft-order-payment-info {
    margin: 20px 0;
  }

  .draft-order-payment-time {
    padding: 20px 0;
    border-top:    1px solid lightgray;
    border-bottom: 1px solid lightgray;
  }
}