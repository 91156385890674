.call-summary-header {
  background-color: #F5F5F5;
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  margin-bottom: 2px;
  cursor: pointer;

  .call-summary-header-wrapper {
    display: flex;
    flex-flow: row;
    justify-content: space-between;

    .call-summary-detail {
      display: flex;

      .call-summary-tab-label {
        font-size: 14px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);
      }
    }
  }

  svg.down-caret {
    &.rotate {
      transform: rotate(180deg);
    }
  }
}