.customer-search-container {
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .loader-wrapper {
    overflow: hidden;
  }

  .empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p {
      display: block;
      margin-top: 24px;
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
  .customer-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2% 5%;
    p {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
    }
    h3 {
      font-size: 20px;
      font-weight: 600;
      color: black;
      -webkit-text-fill-color: black;
      line-height: 22px;
    }
    &:hover {
      cursor: pointer;
      background-color: #ccc3bd;
    }
  }
}
