.infinite-scroll-item {
    display: flex;
    justify-content: center;
    margin-top: 4px;
}

.history-calls-wrapper {
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    display: grid;
    width: 100%;
    height: 100%;

    .infinite-scroll-calls-items-list {
        flex-flow: column;
        align-items: center;
        padding: 35px 48px;
        width: 100%;
        overflow-y: auto;
        max-width: 1024px;
        justify-self: center;
    }

    .infinite-scroll-calls-items-list::-webkit-scrollbar {
        display: none;
    }
}
