.chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .typing-container {
    height: 60px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    .image-border {
      border: #ccc 1px solid;
      height: 40px;
      width: 40px;
      border-radius: 25px;
      text-align: center;

      .avatar {
        height: 38px;
        width: 38px;
        border-radius: 19px;
      }

      h4 {
        color: var(--base-font-color);
        font-size: 24px;
      }
    }
    .typing-dots {
      display: flex;
      flex-direction: row;
      padding-top: 9px;
      margin-left: 20px;
      h5 {
        color: var(--base-font-color);
        font-size: 16px;
      }
    }
  }

  .follow-scroll-button {
    display: none;
  }

  .message-container-chat {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: #ccc 1px solid;
    flex: 1;

    .new-message-item {
      display: -webkit-box;
      flex-direction: row;
      align-items: flex-start;
      margin-top: 10px;
      margin-bottom: 10px;

      &_newmessage {
        align-items: center;
        justify-content: center;
        color: var(--sherbert-color);
        -webkit-text-fill-color: var(--sherbert-color);
        font-size: 16px;
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 100%;
        padding: 0px;

        &_delimiter {
          height: 1px;
          background-color: var(--sherbert-color);
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
          padding: 0px;
          flex: 2;
        }
      }
    }

    .message-item {
      padding: 0px 10px;
      margin-top: 10px;
      margin-bottom: 10px;

      &_hidden_message {
        display: none;
      }

      &_my {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        p {
          opacity: 0.64;
          color: #000000;
          font-family: 'Poppins', sans-serif;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 23px;
          margin-left: 10px;
          cursor: pointer;
        }

        &_info {
          font-weight: 500 !important;
        }

        &_invitation {
          cursor: default !important;
        }

        &_question {
          font-weight: 500 !important;
          cursor: default !important;
          margin-right: 10px;
        }
      }

      &_system {
        align-items: center;
        justify-content: center;
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
        font-size: 16px;
        display: flex;
        flex-direction: row;
        text-align: center;
        width: 100%;

        &_delimiter {
          height: 1px;
          background-color: var(--main-color);
          width: 100%;
          margin-left: 10px;
          margin-right: 10px;
          flex: 2;
        }
      }

      .time {
        padding-left: 60px;
        color: var(--base-font-color);
        font-size: 14px;
        margin-top: 5px;
      }
      .host-time {
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
        padding-right: 10px;
        color: var(--base-font-color);
        font-size: 14px;
        margin-top: 5px;
      }

      .msg-with-image {
        display: flex;
        flex-direction: row;

        .image-system-border {
          border: var(--main-color) 1px solid;
          height: 25px;
          width: 25px;
          border-radius: 13px;
        }

        .image-border {
          border: #ccc 1px solid;
          height: 40px;
          width: 40px;
          border-radius: 25px;
          text-align: center;

          .avatar {
            height: 38px;
            width: 38px;
            border-radius: 19px;
          }

          h4 {
            color: var(--base-font-color);
            font-size: 24px;
          }
        }
        .msg-border {
          border: #ccc 1px solid;
          background-color: #F7F7F7;
          max-width: 70%;
          border-radius: 10px;
          padding: 10px;
          margin-left: 20px;
          h5 {
            font-size: 18px;
            color: var(--base-font-color);
            white-space: pre-wrap;
            overflow-wrap: break-word;
            word-break: break-word;
          }
          a {
            font-size: 18px;
            color: var(--base-font-color);
            white-space: pre-wrap;
            overflow-wrap: break-word;
            word-break: break-word;
          }
        }
      }

      .host-msg-with-image {
        display: flex;
        flex-direction: row;
        align-self: flex-end;
        justify-content: flex-end;

        .host-image-border {
          border: #ccc 1px solid;
          height: 40px;
          width: 40px;
          border-radius: 25px;
          text-align: center;

          .avatar {
            height: 38px;
            width: 38px;
            border-radius: 19px;
          }

          h4 {
            color: var(--base-font-color);
            font-size: 24px;
          }
        }
        .host-msg-border {
          border: #ccc 1px solid;
          background-color: var(--main-color);
          max-width: 70%;
          border-radius: 10px;
          padding: 10px;
          margin-right: 10px;
          h5 {
            font-size: 18px;
            color: white;
            -webkit-text-fill-color: white;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            word-break: break-word;
          }
          a {
            font-size: 18px;
            color: white;
            -webkit-text-fill-color: white;
            white-space: pre-wrap;
            overflow-wrap: break-word;
            word-break: break-word;
          }
        }
      }
    }
  }

  .bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 120px;
    border-top: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
    border-right: #ccc 1px solid;

    .button-start-chat {
      background-color: var(--main-color);
      height: 60px;
      width: 60%;
      align-self: center;
      margin: 0 20%;
      color: white;

      &:hover {
        background-color: var(--main-color-hover) !important;
      }
    }

    &_file-input {
      display: none;
    }

    &_send-image {
      box-sizing: border-box;
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      border: 1px solid black;
      border-radius: 8px;
      background-color: #FFFFFF;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      margin-top: 10px;

      svg {
        fill: black;
      }

      &_gray {
        fill: gray;
        svg {
          fill: gray;
        }
      }
    }

    .text-input {
      width: 100%;
      margin: 20px;
      margin-top: 10px;
      border: #ccc 1px solid;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      color: var(--base-font-color);
      overflow-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
    }

    .button-send {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      border-radius: 8px;
      background-color: var(--main-color);
      margin-right: 20px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: white;
      }
    }

    .button-send-gray {
      background-color: gray;
    }

    .buttons {
      margin: 0px 20px 20px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button-photo {
        border: #ccc 1px solid;
        border-radius: 5px;
        height: 45px;
        width: 45px;
      }
      .button-camera {
        border: #ccc 1px solid;
        border-radius: 5px;
        height: 45px;
        width: 45px;
        padding-left: 5px;
        padding-top: 5px;
      }
    }
  }

  .bottom-container-video {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 120px;
    border-top: #ccc 1px solid;
    border-bottom: #ccc 1px solid;
    border-right: #ccc 1px solid;

    &_file-input {
      display: none;
    }

    &_send-image {
      box-sizing: border-box;
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(0,0,0,0.64);
      border-radius: 8px;
      background-color: #FFFFFF;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      margin-left: 20px;
      margin-top: 10px;

      svg {
        fill: black;
      }

      &_gray {
        fill: gray;
        svg {
          fill: gray;
        }
      }
    }

    .text-input {
      width: 100%;
      margin: 20px;
      margin-top: 10px;
      border: #ccc 1px solid;
      border-radius: 5px;
      padding: 10px;
      font-size: 16px;
      color: var(--base-font-color);
      overflow-wrap: break-word;
      overflow-wrap: break-word;
      word-break: break-word;
      hyphens: auto;
    }

    .button-send {
      height: 40px;
      width: 40px;
      min-height: 40px;
      min-width: 40px;
      border-radius: 8px;
      background-color: var(--main-color);
      margin-right: 20px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        fill: white;
      }
    }

    .button-send-gray {
      background-color: gray;
    }

    .buttons {
      margin: 0px 20px 20px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .button-photo {
        border: #ccc 1px solid;
        border-radius: 5px;
        height: 45px;
        width: 45px;
      }
      .button-camera {
        border: #ccc 1px solid;
        border-radius: 5px;
        height: 45px;
        width: 45px;
        padding-left: 5px;
        padding-top: 5px;
      }
    }
  }

  .green-video-header {
    background-color: var(--main-color);
    height: 60px;
    color: white;
    -webkit-text-fill-color: white;

    .header-video-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 10px;

      .image-border {
        border: gray 1px solid;
        height: 40px;
        width: 40px;
        border-radius: 25px;
        text-align: center;
        background-color: white;

        .avatar {
          height: 38px;
          width: 38px;
          border-radius: 19px;
        }

        h4 {
          color: gray;
          -webkit-text-fill-color: gray;
          font-size: 24px;
        }
      }

      h5 {
        color: white;
        -webkit-text-fill-color: white;
        position: absolute;
        left: 80px;
        top: 15px;
        font-size: large;
      }

      .cross-icon {
        margin-top: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
        z-index: 100;
        color: #00000066;
        -webkit-text-fill-color: #00000066;
        height: 32px;
        width: 32px;
        line-height: 28px;
        text-align: center;
        vertical-align: middle;
        background-color: #ffffffa3;
        border-radius: 50%;
        position: relative;
        transition: 0.15s color, background;
      }
    }
  }

  .green-header {
    background-color: var(--main-color);
    height: 115px;
    color: white;

    .header-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
      padding-top: 10px;

      &_actions {
        padding-top: 5px !important;
      }

      &_video-call {
        box-sizing: border-box;
        height: 41px;
        width: 41px;
        border: 1px solid #979797;
        // opacity: 0.64;
        border-radius: 20px;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding-left: 2px;
      }

      .select-items {
        width: 200px;
        cursor: pointer;

        .css-yk16xz-control {
          background-color: var(--main-color);
          border-color: white;
          cursor: pointer;
        }
        .css-1pahdxg-control {
          background-color: var(--main-color);
          border-color: white;
        }
        .css-tlfecz-indicatorContainer {
          color: white;
        }
        .css-1gtu0rj-indicatorContainer {
          color: white;
        }
        .css-1okebmr-indicatorSeparator {
          background-color: var(--main-color);
        }
        .css-1uccc91-singleValue {
          color: white;
          cursor: pointer;
        }
        .css-26l3qy-menu {
          background-color: var(--main-color);
          margin-top: -10px;
        }
        .css-1n7v3ny-option {
          background-color: green;
          cursor: pointer;
        }
        .css-1fhf3k1-control {
          background-color: var(--main-color);
        }
        .css-107lb6w-singleValue {
          color: white;
        }
      }

      h5 {
        font-size: 18px;
        color: white;
        -webkit-text-fill-color: white;
      }

      .button-close {
        height: 40px;
        width: 100px;
        border-radius: 5px;
        background-color: var(--main-color);
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border: white 1px solid;
        margin-bottom: 20px;

        &:hover {
          background-color: var(--main-color-hover) !important;
        }
      }
    }
  }
}

