.product-details-skeleton-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;

  .product-details-skeleton-left-side {
    display: flex;
    flex: 1;
    flex-flow: column;
    margin-right: 10px;

    .product-details-skeleton-variants {
      display: flex;
      flex-flow: row;
      align-items: center;
    }
  }

  .product-details-skeleton-right-side {
    display: flex;
    flex: 1;
    margin-left: 10px;
    flex-flow: column;
    justify-content: space-between;
  }


}
