.showroom-presets-list {
  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    padding-bottom: 6px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .product-view {
    display: flex;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    .showroom-preset-product-item {
      padding-bottom: 12px;
      max-width: calc(15vh * 0.66 + 2vh);

      .image-with-overlay {
        width: calc(20vh * 0.66);
        min-width: calc(20vh * 0.66);
        height: 20vh;
        margin: 1vh;
      }
      .image-with-overlay-no-click {
        width: calc(15vh * 0.66);
        min-width: calc(15vh * 0.66);
        height: 15vh;
        margin: 1vh;
      }

      .product-title {
        padding: 0 6px;
        font-size: 12px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);
        font-weight: 600;
        text-align: center;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
        box-sizing: content-box;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  .empty-results {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;

    p {
      display: block;
      margin-top: 24px;
      margin-bottom: 0;
      text-align: center;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }
}