.call-order-header {
  background-color: #F5F5F5;
  padding: 12px 16px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  cursor: pointer;

  .call-order-detail {
    display: flex;
    flex-flow: column;
    .date {
      color: #3F3F3F;
      -webkit-text-fill-color: #3F3F3F;
      font-size: 12px;
      margin-bottom: 6px;
    }

    .customer-name {
      font-size: 14px;
      color: var(--base-font-color);
      -webkit-text-fill-color: var(--base-font-color);
    }
  }
}
