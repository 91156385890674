.draft-order-shipping {
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;

  .radio-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 16px 0;

    .button-circle {
      width: 20px;
      height: 20px;
      border: 1px solid var(--main-color);
      border-radius: 50%;
      padding: 2px;

      .checked-button {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: var(--main-color);
      }
    }

    span {
      margin-left: 8px;
      font-size: 14px;
      font-weight: unset;
    }
  }

  .custom-shipping {
    display: grid;
    grid-template-columns: 45% 45%;
    justify-content: space-between;
    margin-bottom: 20px;
    visibility: hidden;
    height: 0;

    &.visible {
      visibility: visible;
      height: auto;
    }

    .input-symbol {
      position: relative;
    }

    .input-symbol:after {
      position: absolute;
      top: 0;
      right: 8px;
      margin-top: 16px;
      content: attr(symbol);
    }

    .shipping-input {
      box-sizing: border-box;
      border: 1px solid #CCC3BD;
      border-radius: 8px;
      margin-top: 8px;
      padding: 8px;
      width: 100%;
    }

    p {
      color: gray;
    }

    .section {
      display: grid;
      grid-row: 2;
      grid-template-rows: 20px auto;

      input[type='number'] {
        -moz-appearance:textfield;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  .draft-order-shipping-buttons {
    display: flex;
    width: 50%;
    justify-content: space-around;
    align-self: center;
    margin: 0 0 20px 50%;
  }

  .draft-order-shipping-edit-buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-self: center;
    margin-bottom: 20px;
  }
}