.preparation-tab-save-action {
  align-items: center;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: flex-end;

  .preset-button {
    color: blue;
    cursor: pointer;
    justify-self: flex-end;

    &.disabled {
      color: gray;
      -webkit-text-fill-color: grey;
      cursor: default;
    }
  }

  .tooltiptext {
    visibility: hidden;
    display: inline;
    width: 160px;
    background-color: #6e76f2;
    border-radius: 4px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 14px;
    color: white;
    -webkit-text-fill-color: white;
    text-align: center;
  }

  &:hover .tooltiptext {
    visibility: visible;
  }

  .action-text {
    margin-left: 4px;
    font-size: 14px;
    color: #6e76f2;
    -webkit-text-fill-color: #6e76f2;

    &.hidden {
      display: none;
    }
  }

  .min-width {
    min-width: 90px;
    align-items: center;
    justify-content: center;
  }

  .more-call-action-modal {
    opacity: 0;
    position: absolute;
    width: 150px;
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    right: -50px;
    align-items: flex-start;
    justify-content: space-evenly;
    border-radius: 6px;
    margin: auto;
    box-sizing: border-box;
    outline: none;
    border: none;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.30);
    transition: .14s ease-in-out all;
    pointer-events: none;
    will-change: opacity, transform;

    &.bottom {
      top: 20px;
      transform: translateY(20px);

      &:after {
        top: -7px;
        border-bottom: 8px solid #fff;
      }
    }

    &.bottom-left {
      top: 20px;
      right: -10px;
      transform: translateY(20px);

      &:after {
        top: -7px;
        border-bottom: 8px solid #fff;
      }
    }

    &.visible {
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }

    ul {
      width: 100%;
      list-style: none;
      padding: unset;
      margin: 8px;
      color: var(--base-font-color);
      -webkit-text-fill-color: var(--base-font-color);

      li {
        line-height: 32px;
        cursor: pointer;
        width: 100%;

        &.disabled {
          cursor: default;
          color: gray;
          -webkit-text-fill-color: grey;
        }
      }
    }
  }
}
