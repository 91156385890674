.row-container {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: minmax(350px, 100%) minmax(330px, 450px);
  height: 100%;
  overflow: hidden;

  .customer-block {
    grid-row: 1;
    grid-column: 1;
    overflow: hidden;
  }

  .product-block-container {
    grid-row: 1;
    grid-column: 2;
    overflow: hidden;
  }
}
