.search-input-dropdown-field-overlay {
  position: relative;
  display: inline-block;
  width: 100%;
  z-index: unset;

  &.visible-dropdown {
    z-index: 200;
  }

  .search-input-dropdown-field-input {
    box-sizing: border-box;
    height: 42px;
    border: 1px solid #ccc3bd;
    border-radius: 42px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    margin-top: 20px;

    input {
      margin-left: 16px;
      height: 100%;
      width: 100%;
      outline: none;
      border: none;
      font-size: 16px;

      &:disabled {
        color: gray;
      }

      &:disabled::-webkit-input-placeholder {
        color: lightgray;
      }
      &:disabled:-moz-placeholder {
        color: lightgray;
      }
      &:disabled::-moz-placeholder {
        color: lightgray;
      }
      &:disabled:-ms-input-placeholder {
        color: lightgray;
      }
    }

    svg {
      cursor: pointer;
      color: #cfc8bb;
      -webkit-text-fill-color: #cfc8bb;
      margin-right: 16px;
    }
  }

  .search-input-dropdown-field-result {
    min-height: 100px;
    max-height: 400px;
    background-color: #FFFFFF;
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.16);
    z-index: 101;
    border: 1px solid #ccc3bd;
    border-radius: 20px;
    overflow: auto;
    width: 100%;
    display: block;
    position: absolute;

    .empty-results {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      p {
        display: block;
        margin-top: 24px;
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }

    &.searching {
      height: 100px;
    }
  }

}