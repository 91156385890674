.draft-order-products {
  .draft-order-products-header {
    justify-content: space-between;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    margin-top: 10px;

    .custom-product-button {
      color: blue;
      cursor: pointer;
      justify-self: flex-end;
    }
  }

  .click-outside {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    display: none;

    &.visible {
      display: block;
      z-index: 199;
    }
  }
}
