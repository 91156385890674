.call-details-showroom-item {
  display: flex;
  padding: 16px 24px;
  border-bottom: 1px solid #e8e8e8;

  &.selected {
    background-color: #e1e1e1;
  }

  .main-image {
    border-radius: 10px;
    object-fit: contain;
    height: 80px;
    max-width: 60px;
  }

  .product-description-wrapper {
    display: flex;
    width: 100%;

    .checkbox-wrapper {
      display: flex;
      flex-flow: column;
      width: 40%;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .checkbox {
        margin-right: 0;
        margin-bottom: 12px;
      }
      input[type='checkbox'] {
        height: 0;
      }
    }

    .product-description {
      margin-left: 20px;
      display: flex;
      flex-flow: column;
      width: 100%;

      span {
        display: flex;
      }

      .product-title {
        font-weight: bold;
      }

      .variant-title {
        font-weight: unset;
        font-size: 14px;
        color: grey;
      }

      .price-container {
        cursor: pointer;
      }
    }
  }
}
