.sidebar-tab-bar {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid lightgray;
  margin-top: 16px;

  .sidebar-tab {
    cursor: pointer;
    padding: 0 10px 4px 10px;
    border-bottom: 2px solid transparent;
    margin-bottom: -2px;
    align-items: center;
    display: flex;

    .sidebar-tab-icon {
      margin-right: 10px;
    }

    &.selected {
      border-bottom: 2px solid var(--main-color);
    }

    .sidebar-tab-label {
      font-size: 15px;
      color: #66676C;

      &.selected {
        color: var(--main-color);
      }
    }
    .sidebar-tab-counter {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      margin: 0 0 10px 2px;
      font-size: 12px;
      color: white;
      background-color: #febe88;
    }
  }

}

