.confirmation-dialog {
    min-width: 300px;
}

.rbc-slot-selection {
    opacity: .4;
    background: var(--light-font-color);
    color: white;
}

.settings-container-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.settings-container-wrapper::-webkit-scrollbar {
    display: none;
}

.rbc-header {
    font-weight: 400;
    border: 0;
}

.rbc-time-view {
    border: 0;
}

.rbc-time-header.rbc-overflowing {
    border: 0;
}

.rbc-header+.rbc-header {
    border: 0;
}

.rbc-allday-cell {
    display: none;
}

.slot-shop-closed {
    background-color: rgba(0, 0, 0, 0.03);
}

.day-shop-closed {
    background-color: rgba(0, 0, 0, 0.03);
}

.rbc-toolbar {
    .rbc-btn-group {
        display: flex;

        button {
            border-radius: 4px;

            &:nth-child(1) {
                order: 1;
                border-radius: 0;

            }

            &:nth-child(2) {
                order: 0;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            &:nth-child(3) {
                order: 2;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

            }
        }
    }
}

.rbc-day-slot .rbc-events-container {
    margin: 0;
}

.rbc-current-time-indicator {
    top: 42.8472%;
    background-color: var(--success-color);
    height: 2px;
}

.rbc-slot-selecting {
    border-radius: 0;
    font-weight: 500;
    padding-top: 4px;
    font-size: 14px;
}

.rbc-timeslot-group {
    min-height: 60px;
}

.rbc-time-header-content {
    border: 0;
}

.rbc-time-slot {
    display: flex;

    .rbc-label {
        font-size: 12px;
        opacity: .4;
    }
}

.rbc-toolbar .rbc-toolbar-label {
    font-size: 20px;
    font-weight: 500;
    order: 0;
    text-align: start;
}

.rbc-toolbar .rbc-btn-group {
    order: 1;
}

.custom-toolbar-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px 0;
    margin-bottom: 20px;

    .instructions-content {
        grid-row: 1;
        grid-column: 1;
        font-size: 18px;
        opacity: .6;
        align-self: center;
        white-space: pre-line;
    }

    .title {
        grid-row: 2;
        grid-column: 1;
    }

    .edit-mode-container {
        grid-row: 1;
        grid-column: 2;
        display: flex;
        justify-self: flex-end;
        align-self: center;
        align-items: center;


        label {
            display: flex;
            user-select: none;

            .toggle-message {
                font-size: 16px;
                margin-right: 1ch;
            }
        }
    }

    .navigation-group {
        grid-row: 2;
        grid-column: 2;
        justify-self: flex-end;

        .button {
            font-size: 14px;
            font-weight: 500;
            border-radius: 14px;
            background: #ffffff;
            display: flex;
            border: 1px solid rgba(0, 0, 0, .3);
            padding: .4em 1em;
            display: inline-block;
            text-align: center;
            vertical-align: middle;

            &:nth-child(1) {
                border-radius: 14px 0 0 14px;
            }

            &:nth-child(2) {
                border-right: none;
                border-left: none;
                border-radius: 0;
            }

            &:nth-child(3) {
                border-radius: 0 14px 14px 0;
            }
        }
    }
}

.availability-calendar-container {
    height: calc(100% - 60px);
    margin: 50px 32px 10px 32px;

    &.show-legend {
        height: calc(100% - 100px);
        margin: 50px 32px;
    }

    .calendar-legend {
        height: 100%;
        padding: 10px 0;
        flex-flow: row;
        display: flex;
    }
}

