.showroom-preset-save-modal {
  height: 280px;
  width: 460px;
  display: flex;
  flex-direction: column;

  .close-button {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    border: 1px solid #999999;
    border-radius: 16px;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }

  .showroom-preset-wrapper {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin-top: 22px;

    .showroom-preset-header {
      margin-top: -40px;
    }

    .showroom-preset-data-wrapper {
      margin-top: 0.8rem;
      display: flex;
      flex-flow: column;
      flex: 1;

      .caazam-input {
        border-radius: 32px;
        height: 38px;
        border: 1px solid var(--light-font-color);
        outline: none;
        color: black;
        -webkit-text-fill-color: black;
        padding: 16px;
        width: 100%;
        box-sizing: border-box;
        text-align: start;
      }

      .checkbox-wrapper {
        margin-top: 6px;
      }
    }
  }


}