.scheduler-container {
  display: flex;
  flex-direction: column;
  height: 606px;
  align-items: center;
  font-size: 16px;

  header {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    border-bottom: 1px solid #c0c0c0;
    align-items: center;
    width: 100%;
    h3 {
      margin-bottom: unset;
    }
    .tabs {
      width: 100%;
      padding: 0px 12px;
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
      cursor: pointer;
      margin: 8px;
      .navigation {
        width: 24px;
      }
      .date-title {
        text-align: center;
        .selected {
          color: black;
          -webkit-text-fill-color: black;
          border-bottom: 2px solid black;
        }
      }
    }
  }
  main {
    height: 55%;
    overflow-y: auto;
    width: 100%;
    .slots-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 22px 0;
      .time-slot {
        height: 58px;
        width: 360px;
        box-sizing: border-box;
        border: 1px solid var(--main-color);
        border-radius: 28px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
        cursor: pointer;
        > span:first-child {
          font-weight: 500;
        }
        &.unavailable {
          border: 1px solid var(--light-font-color);
          color: var(--light-font-color);
          -webkit-text-fill-color: var(--light-font-color);
          cursor: auto;
        }
        &.selected {
          background-color: var(--main-color);
          color: white;
          -webkit-text-fill-color: white;
        }
        &.scheduled {
          background-color: var(--main-color);
          color: white;
          -webkit-text-fill-color: white;
          opacity: .4;
          pointer-events: none;
        }
      }
    }
  }
  footer {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top: 1px solid #c0c0c0;
    align-items: center;
    .actions-container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      height: 100%;
      button {
        &.disabled {
          background-color: var(--light-font-color) !important;
        }
      }
      span {
        cursor: pointer;
      }
    }
    &.confirm-actions {
      border: none;
      flex-direction: row;
      justify-content: space-evenly;
      > div:first-child {
        cursor: pointer;
      }
      .add-to-calendar-modal {
        width: 240px;
        height: 240px;
        background: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 360px;
        right: 120px;
        padding: 20px;
        align-items: flex-start;
        justify-content: space-evenly;
        border-radius: 16px;
        margin: auto;
        box-sizing: border-box;
        outline: none;
        border: none;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.32);
        > h3 {
          margin: 8px 0;
        }
        > span {
          display: flex;
          flex-direction: column;
          > a {
            text-decoration: none;
            color: rgba(0, 0, 0, 0.32);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.32);
          }
        }
        > button {
          width: 100%;
          margin-top: 20px;
          font-size: 16px;
        }
      }
    }
  }
  section.confirm-schedule {
    height: 90%;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    h3,
    p {
      color: var(--base-font-color);
      -webkit-text-fill-color: var(--base-font-color);
      margin-bottom: 16px;
      text-align: center;
    }
    > span {
      display: flex;
      align-items: center;
      margin-top: 20px;
      text-decoration: underline;
    }

    .copy-link-container {
      width: 100%;
    }
  }
}

.call-details-modal {
  .scheduler-container {
    footer {
      height: 16%;
    }
  }
}
