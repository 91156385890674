ul.daily-calendar {
  list-style-type: none;
  padding: unset;
  display: grid;
  width: 100%;

  &.history {
    gap: 4px 0;
  }

  &.scheduled {
    gap: 4px 0;
  }

  .customers-message {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
  }

  @media (max-width: 1024px) {
    margin: 24px auto;
    width: 560px;
  }
}