  .search-container {
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-flow: column;

    .search-container-wrapper {
      display: flex;
      align-items: center;

      .search-panel-form-container {
        width: auto;
        flex: 1 1;
      }

      .load-showroom-button {
        color: blue;
        white-space: nowrap;
        flex: 0 1;
        cursor: pointer;
        margin-left: 10px;
      }
    }

    .search-product-results {
      padding-top: 24px;
    }
  
    .empty {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  
    .empty-results {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
  
      p {
        display: block;
        margin-top: 24px;
        margin-bottom: 0;
  
        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }

.footer {
  height: 56px;

  .loader {
    height: 20px;
    width: 20px;
    border: 4px solid white;
    border-bottom: 4px solid var(--main-color);
    border-right: 4px solid var(--main-color);
    border-top: 4px solid var(--main-color);
  }
}
