.client-calls-items-list {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 48px;
  margin: 12px 0;
  width: calc(100% - 450px);
  justify-self: center;
  overflow-y: auto;
  height: calc(100% - 24px);

  .infinite-scroll-item {
    display: flex;
    justify-content: center;
    margin-top: 4px;
    width: 100%;
  }

  .client-calls-header-container {
    width: 100%;
    display: flex;
    align-items: center;

    .list-header {
      font-size: 20px;
      font-weight: bold;
      color: #3F3F3F;
      width: 100%;
    }

    .refresh-button {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 4px;

      &:active {
        color: gray;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .client-calls-items-list  {
    padding: 0 24px;
  }
}

@media screen and (max-width: 1350px) {
  .client-calls-items-list  {
    padding: 0 12px;
  }
}

@media screen and (max-width: 1200px) {
  .client-calls-items-list  {
    padding: 0;
  }
}

.client-calls-items-list::-webkit-scrollbar {
  display: none;
}
