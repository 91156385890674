.remove-preset-modal {
  height: 180px;
  width: 460px;
  display: flex;
  flex-direction: column;

  .close-button {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    border: 1px solid #999999;
    border-radius: 16px;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }

  .remove-preset-wrapper {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin-top: 12px;
  }
}