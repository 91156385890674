.add-to-calendar-modal {
    opacity: 0;
    position: absolute;
    width: 100%;
    min-width: 100px;
    background: #fff;
    z-index: 1;
    display: flex;
    flex-direction: column;
    right: 0;
    padding: 0px 12px;
    align-items: flex-start;
    justify-content: space-evenly;
    border-radius: 6px;
    margin: auto;
    box-sizing: border-box;
    outline: none;
    border: none;
    box-shadow: 0 0 14px rgba(0, 0, 0, 0.30);
    transition: .14s ease-in-out all;
    pointer-events: none;
    will-change: opacity, transform;

    &:after {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        content: '';
        display: block;
        z-index: 2;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
    }

    &.top {
        top: auto !important;
        bottom: 30px;
        transform: translateY(-20px);

        &:after {
            bottom: -7px;
            border-top: 8px solid #fff;
        }
    }

    &.bottom {
        top: 30px;
        transform: translateY(20px);

        &:after {
            top: -7px;
            border-bottom: 8px solid #fff;
        }
    }

    &.visible {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    }

    .add-to-calendar-modal-close-button {
        height: 32px;
    }

    ul {
        list-style: none;
        padding: unset;
        margin: 8px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);

        li {
            line-height: 32px;
            cursor: pointer;
        }
    }
}
