.search-product-item-component {
  padding: 0 20px;
  margin: 10px 0;
  display: grid;

  &:hover {
    background: rgba(0, 0, 0, 0.04);
    cursor: pointer;
  }

  .main-image {
    object-fit: contain;
    border-radius: 10px;
    height: 80px;
    max-width: 60px;
  }

  .product-description-wrapper {
    display: flex;
    width: 100%;
    z-index: 1;

    .product-description {
      margin-left: 20px;
      display: flex;
      flex-flow: column;
      width: 100%;

      span {
        display: flex;
      }

      .product-title {
        font-weight: bold;
      }

      .variant-title {
        font-weight: unset;
        font-size: 14px;
        color: grey;
      }
    }
  }

  .search-product-item-description-container {
    display: flex;
  }

  .checkbox-wrapper {
    display: flex;
    flex-flow: column;
    width: 30%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;

    .checkbox {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  .error-message {
    font-size: 12px;
    color: red;
  }

  .remove-selected-product-product {
    align-self: flex-end;
    justify-self: center;
    cursor: pointer;
    height: 100%;
    align-items: center;
    display: flex;
    margin-right: 35px;

    svg {
      width: 20px;
      height: 20px;
    }

    .prefix__active-path {
      fill: #be1919;
    }
  }
}