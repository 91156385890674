.carousel-gallery-dots {
    display: grid;
    grid-auto-flow: column;
    gap: var(--dots-gap);
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    .carousel-gallery-dot-container {
        transition: .14s transform;
        position: relative;

        &:after {
            position: absolute;
            content: '';
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: var(--dots-border) solid var(--main-color);
            opacity: 0;
            transition: .14s opacity;
        }

        &:before {
            position: absolute;
            content: '';
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            border-radius: 50%;
            background: #fff;
            opacity: 0;
            transition: .14s opacity;
        }

        &.custom-border {
            .carousel-gallery-dot {
                &.empty-dots {
                    border: var(--dots-border) solid transparent;
                }
            }
        }

        &.active {
            &:before {
                opacity: 1;
            }
        }

        &.custom-border {
            &:after {
                opacity: 1;
            }
        }

        .carousel-gallery-dot {
            height: var(--dots-size);
            width: var(--dots-size);
            border-radius: 50%;
            position: relative;

            &:not(.empty-dots) {
                background: #fff;
            }

            &.empty-dots {
                border: var(--dots-border) solid #fff;
            }

            &.scaled-down {
                &:after {}
            }
        }
    }
}
