.sidebar-client-tabs {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.visible {
    visibility: visible;
    z-index: 1;
  }

  .customer-profile-side-bar-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .customer-profile-found-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 4px;

      h3 {
        font-size: 20px;
        color: #3f3f3f;
      }

      .customer-search-panel-back-button {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: .14s opacity;
        will-change: opacity;

        &:hover {
          opacity: .4;
        }
      }
    }

    p {
      font-size: 16px;
      color: #3f3f3f;
      font-weight: 500;
    }

    .customer-search-button {
      grid-column: 2;
      border-radius: 50%;
      cursor: pointer;
      padding: 12px;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f5f5f5;
      transition: .2s opacity;
      will-change: opacity;

      &:disabled {
        opacity: .4;
        pointer-events: none;
      }
    }
  }

  .customer-profile-found-client-header {
    margin: 6px 24px;
    display: flex;
    flex-direction: column;

    h3 {
      font-size: 20px;
      color: #3f3f3f;
    }

    p {
      font-size: 14px;
      color: #66676C;
    }
  }
}