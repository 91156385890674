.showroom-component-wrapper {
  display: flex;
  width: 100%;
  flex: 1;
  flex-flow: column;
  overflow-y: auto;

  .empty-showroom {
    align-items: center;
    display: flex;
    padding: 24px;
    flex-flow: column;

    .empty-showroom-image {
      width: 100%;
      height: calc(100% - 320px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}