.btn-plus-wrapper {
  position: absolute;
  bottom: 60px;
  right: 60px;
  z-index: 100;

  .btn-plus {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: var(--main-color);
    position: relative;
    will-change: transform, opacity;
    transition: transform .5s .14s, opacity .5s .14s;
    cursor: pointer;

    &:after {
      position: absolute;
      content: '+';
      color: white;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      font-size: 40px;
    }
  }

  ul {
    position: absolute;
    list-style-type: none;
    transform-origin: center;
    top: 50%;
    left: 50%;
    transform: translate3d(-70%, -80%, 0) scale(0);
    width: 220px;
    border-radius: 14px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    background-color: white;
    padding: 10px;
    li {
      cursor: pointer;
      padding: 10px;
      &:hover {
        background-color: var(--light-font-color);
        border-radius: 12px;
      }
    }
  }

  &.modalVisible {
    .btn-plus {
      opacity: 0;
      transition: 0.14s transform, 0.14s opacity;
    }

    ul {
      transform: translate3d(-70%, -80%, 0) scale(1);
    }
  }


  &.show {
    animation: side-bar-show 500ms forwards;
  }

  &.close {
    animation: side-bar-hide 500ms forwards;
  }

  @keyframes side-bar-hide {
    0% {
      transform: translateX(-450px);
    }
    100% {
      transform: translateX(0);
    }
  }

  @keyframes side-bar-show {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-450px);
    }
  }
}