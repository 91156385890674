.option-cotainer-wrapper {
    display: flex;
    overflow: hidden;
    transition: .2s ease-in-out;
    max-height: 0;

    &.visible {
        max-height: 50px;
    }

    .option-cotainer {
        display: grid;
        grid-template-columns: 81px auto;
        padding: .5em 0;
        gap: 0 1ch;
        width: 100%;

        .option-label {
            justify-self: flex-end;
        }

        .option-value {
            justify-self: flex-start;
        }

        .react-datepicker-wrapper {
            .react-datepicker__input-container {
                input {
                    &:disabled {
                        opacity: .4;
                    }
                }
                .react-datepicker-ignore-onclickoutside {
                    width: 100%;
                }
            }
        }
    }
}



.custom-datepicker-container {
    display: flex;
    flex-flow: column;
    background: #fff;

    .datepicker-container {
        display: flex;
        flex-flow: row nowrap;
    }
}
