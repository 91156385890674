.collapsible-component {
  .content {
    border-radius: 5px;
  }

  .content-parent {
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: .3s opacity, height ease 0.4s;
  }

  .content-show {
    opacity: 1;
    transition: .5s opacity, height ease 0.4s;
  }
}