.section-video-settings-processors {
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  height: 240px;

  &_button {
    width: 48px;
    height: 48px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    border: 1px hsl(0, 0%, 80%) solid;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 0;
    visibility: hidden;

    &.active {
      visibility: visible;
      opacity: 1;
      transition: all .5s ease-in;
    }


  }

  &_upload {
    display: none;
  }

}