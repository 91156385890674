.form-responses-tab {
  visibility: hidden;
  position: absolute;
  transform: translateX(450px);
  width: 100%;
  overflow-y: auto;
  height: 100%;

  .form-responses-item-container {
    margin: 0 16px;
    padding: 10px 0;

    .form-responses-item-label {
      font-size: 14px;
      color: #66676c
    }

    .form-responses-item-value {
      margin-top: 4px;
      background-color: #e9f0ef;
      border-radius: 4px;
      padding: 5px 10px;

      .form-responses-item {
        font-size: 14px;
        color: #3f3f3f;
      }
    }
  }

  &.visible {
    visibility: visible;
    transform: translateX(0px);
    z-index: 1;
  }
}