.email-events-modal-container {
  height: 260px;
  width: 560px;
  display: flex;
  flex-direction: column;

  .close-button {
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    border: 1px solid #999999;
    border-radius: 16px;
    background-color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }

  .email-events-modal-wrapper {
    display: flex;
    flex-flow: column;
    flex: 1;
    margin-top: 22px;

    .email-events-modal-header {
      margin-top: -40px;
    }

    .email-events-modal-data-wrapper {
      display: flex;
      flex-flow: column;
      overflow-y: auto;
      height: 160px;

      .row {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-start;

        .data {
          flex: 1;
        }

        .event-name {
          color: black;
          -webkit-text-fill-color: black;
        }

        span {
          color: gray;
          -webkit-text-fill-color: gray;
          font-size: 13px;
        }
      }
    }
  }
}