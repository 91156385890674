.page-header {
  background-color: var(--midnight-color);
  color: white;
  -webkit-text-fill-color: white;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;

  .notification-warning {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: black;
    -webkit-text-fill-color: black;
    background-color: white;
    padding: 8px;
    border-radius: 2px;
  }
  .small {
    width: 50%;
  }
  .start {
    display: grid;
    grid-auto-flow: column;
    gap: 8px;
    align-items: center;
    .header-logo {
      margin-right: 0.5em;
      height: 33px;
    }
    .shop-name {
      margin: unset;
      font-weight: 500;
    }
    button {
      svg.down-caret {
        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    .team-badge {
      font-size: 16px;
      display: flex;
      position: relative;
      overflow: hidden;

      p {
        margin: 0;
        animation: showHostTeam .14s ease-in-out forwards;
      }
    }
  }
  .end {
    display: grid;
    grid-template-columns: repeat(4, auto);
    column-gap: 16px;
    align-items: center;
  }
  .action-container {
    position: relative;
    display: flex;
 
    hr {
      border: 1px solid rgba(151, 151, 151, 0.4);
      width: 320px;
    }
    .indicator {
      border-radius: 50%;
      text-align: center;
      width: 24px;
      height: 24px;
      font-size: 14px;
      background: var(--error-color);
      position: absolute;
      left: 10px;
      top: -10px;
    }
    .nav-popover {
      position: absolute;
      background: white;
      color: black;
      -webkit-text-fill-color: black;
      border-radius: 16px;
      z-index: 102;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
      right: 0;
      top: 28px;
      display: none;
      flex-direction: column;
      box-sizing: border-box;
      &.visible {
        display: flex;
      }
      &.copy-link {
        width: 320px;
        height: 145px;
        display: flex;
        margin-left: 50px;
        p:last-child {
          text-align: center;
          text-decoration: underline;
        }
        h4 {
          padding-top: 10px;
          padding-left: 16px;
        }
        p {
          padding-left: 16px;
        }
        div {
          margin: 7px;
        }
      }

      .customer-message-container {
        grid-column: 1/3;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        overflow: hidden;

        &.visible {
          flex-flow: column;

          .message {
            white-space: normal;
          }

          .toggle-message-button {
            align-self: flex-end;
          }
        }

        .message {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          color: #3f3f3f;
          -webkit-text-fill-color: #3f3f3f;
        }

        .toggle-message-button {
          font-size: 12px;
          grid-column: auto;
          grid-row: auto;
          white-space: nowrap;
          align-self: center;
        }
      }

      &.user-menu {
        width: 140px;
        padding: unset;
        > ul {
          > li {
            line-height: 18px;
            padding: 9px 0 9px 16px;
            &:not(:last-child) {
              border-bottom: 1px solid rgba(0, 0, 0, 0.16);
            }
          }
        }
        ul {
          list-style: none;
          padding: unset;
          font-size: 12px;
          color: var(--base-font-color);
          -webkit-text-fill-color: var(--base-font-color);
          &.user-actions {
            li strong {
              &.success {
                color: var(--main-color);
                -webkit-text-fill-color: var(--main-color);
              }
              &.error {
                color: var(--error-color);
                -webkit-text-fill-color: var(--error-color);
              }
            }
          }
        }
      }
      &.notifications,
      &.shop-list {
        height: 400px;
        width: 320px;
        padding: unset;
        h5 {
          padding: 16px;
        }
        p {
          text-align: center;
          margin: auto;
        }
        ul {
          list-style-type: none;
          padding: 1px 0;
          overflow-y: scroll;
        }
      }
      &.notifications {
        ul {
          li {
            display: grid;
            grid-template-columns: 250px 30px;
            grid-template-rows: auto 26px 20px 20px;
            padding: 16px;
            box-shadow: 0 -1px 0 0 rgba(151, 151, 151, 0.4);
            p {
              margin: unset;
              text-align: unset;
              &:nth-child(2) {
                grid-column: 1/2;
                grid-row: 2/3;
              }
              &:nth-child(3) {
                grid-column: 1/2;
                grid-row: 3/4;
              }
            }
            button {
              grid-column: 2/3;
              grid-row: 1/4;
              align-self: center;
              .button-loader {
                width: 20px !important;
                height: 20px !important;
              }
            }
          }
        }
      }
      &.shop-list {
        right: -160px;
        ul {
          padding: 16px 20px;
          li {
            height: 70px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            cursor: pointer;
            p {
              margin: unset;
            }
          }
        }
      }
      h4 {
        font-size: 16px;
      }
      p {
        font-size: 12px;
        color: var(--base-font-color);
        -webkit-text-fill-color: var(--base-font-color);
      }
    }
  }
}

@keyframes showHostTeam {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
