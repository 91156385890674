.post-call-feedback-container {
  width: 550px;
  display: flex;
  flex-direction: column;
  font-size: 18px;

  h3 {
    margin-bottom: 30px !important;
  }

  .send-button {
    align-self: center;
    margin-top: 30px !important;
  }

  .other-issues-input {
    margin-top: 6px;
    border-radius: 16px;
    border: solid 1px var(--base-font-color);
    padding: 10px;
  }
  
  .validation-text {
    padding-top: 2px;
    font-size: 11px;
    text-align: center;
  }

  .validation-text:empty {
    height: 19px;
  }
}
