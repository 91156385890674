.signup-page {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover !important;
  background: url(./../../assets/media/screen.svg) no-repeat right/contain;
  overflow: hidden;
  .brand-logo {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 42px;
  }
  .background-element {
    position: absolute;
    width: 60%;
    height: 130%;
    top: -105px;
    left: -130px;
  }
  .signup-form-container {
    display: grid;
    grid-template-rows: 246px 44px;
    align-items: center;
    width: 35%;
    min-width: 376px;
    margin-top: calc(50vh - 145px);
    z-index: 100;
    &.signup {
      grid-template-rows: 368px 44px;
      margin-top: calc(50vh - 206px);
    }
    > * {
      margin: auto;
    }
    form {
      width: 376px;
      height: 246px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      justify-content: space-between;
      z-index: 100;
      .form-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      &.create-account {
        height: 368px;
        .name-inputs {
          display: flex;
          justify-content: space-between;
          > input {
            width: 184px;
          }
        }
      }
      h2 {
        font-size: 24px;
        line-height: 35px;
      }
    }
    p:last-child {
      width: 376px;
      z-index: 100;
    }
  }
}
