.logo-loader-container-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  .logo {
    width: 84px;
    height: 84px;
    will-change: opacity;
    opacity:1;
    animation: logoAnimation 2.5s infinite;
  }
}

@keyframes logoAnimation {
  0% {
    opacity:1;
    -webkit-transform: scale(1) rotate(-360deg);
    transform: scale(1) rotate(-360deg);
  }
  25% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
  50% {
    opacity:0.7;
    -webkit-transform: scale(1.3) rotate(-360deg);
    transform: scale(1.3) rotate(-360deg);
  }
  100% {
    opacity:1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
