.modal-overlay {
  .modal {
    h3,
    p {
      margin-bottom: 16px;
      display: block;
    }
    .actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button:first-child {
        margin-right: 10px;
      }
    }
  }
}
