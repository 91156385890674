.timezone-selector {
  position: relative;
  display: inline-block;

  .timezone-selector-button {
    width: 100%;

    .slots-timezone-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
    }

    svg.down-caret {
      color: rgba(0, 0, 0, 0.4);
      &.rotate {
        transform: rotate(180deg);
      }
    }
  }

  span {
    margin-bottom: 0;
    padding: 4px 0;
  }

  .locale-item {
    padding: 10px;
    line-height: 1;
    position: relative;
    width: 100%;
    text-align: start;
    flex-flow: row;
    display: flex;
    justify-content: space-between;

    .timezone-time {
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
    }

    &:after,
    &:before {
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      background: rgba(0, 0, 0, .14);
      opacity: 0;
      transition: .14s opacity;
      will-change: opacity;
    }

    &:hover {
      &:before {
        opacity: .4;
      }
    }

    &.selected {
      &:after {
        opacity: 1;
      }
    }
  }
}