.client-profile-chart-container {

  &.purchase-data {
    .circle-purchase-animation {
      justify-content: center;
      align-items: center;
      display: flex;
      transform-origin: center;
      animation: 1500ms 0s progressAnimation 0 backwards;

      &.running {
        animation-play-state: running;
        animation-iteration-count: infinite;
      }

      &.reset {
        animation-iteration-count: 0;
        animation-play-state: running;
      }

      svg {
        height: 100%;
        width: 100%;
        max-width: 120px;
        max-height: 120px;
        aspect-ratio: 1;
      }
    }
  }

  @keyframes progressAnimation {
    0% {transform: rotate(0)}
    100% {transform: rotate(360deg)}
  }

}
