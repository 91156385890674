.modal-overlay {
  .first-load-modal {
    height: 180px;
    width: 480px;
    border-radius: 32px;
    background-color: #ffffff;
    box-shadow: inset 0 -4px 0 0 #000000;
    display: flex;
    flex-direction: column;
    position: relative;
    .available {
      color: var(--main-color);
    }
    .unavailable {
      color: var(--error-color);
    }
    .actions {
      button:first-child {
        font-size: 14px !important;
      }
    }
  }
}
