.image-crop-container {
   display: grid;
   grid-template-rows: auto 1fr auto;
   gap: 1rem;
   height: 100%;

   .image-cropper-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-column: 1 / 3;
    grid-row: 2;
    overflow: hidden;
    position: relative;
    will-change: opacity;
    transition: .3s opacity;

    .upload-progress {
      opacity: 0;
      visibility: hidden;
      will-change: opacity;
      transition: .3s opacity;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      display: grid;
      align-content: center;

      .loading-state {
        width: 100%;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        font-size: 20px;
        font-weight: 500;

        &:before {
          content: attr(succes-message);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          text-align: center;
          transform: translateY(100%);
          color: var(--main-color);
          -webkit-text-fill-color: var(--main-color);
        }

        &:after {
          content: attr(error-message);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          text-align: center;
          transform: translateY(100%);
          color: var(--error-color);
          -webkit-text-fill-color: var(--error-color);
        }
      }
    }

    .side-container {
      display: flex;
      grid-row: 1/3;
      height: 100%;
    }
    
    .image-cropper-container {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: auto;
      background-color: rgba(0, 0, 0, 0.5);
      transition: .3s;

      .image-cropper {
        grid-column: 2;
        grid-row: 2;
      }
    }
   }

   .cropper-btn {
    will-change: opacity;
    transition: .3s opacity;

     &.submit {
      justify-self: flex-end;
     }
  }

   &.loading {
    .image-cropper-wrapper {
      .image-cropper-container {
        opacity: .2;
        pointer-events: none;
      }

      .upload-progress {
        opacity: 1;
        visibility: visible;

        .loading-state {
          .loading-message {
            animation: loadingState .3s;
          }

          &.error {
            .loading-message {
              animation: hideLoadingState .3s;
              transform: translateY(-100%);
            }

            &:after {
              transform: translateY(0);
              animation: loadingState .3s;
            }
          }

          &.success {
            .loading-message {
              animation: hideLoadingState .3s;
              transform: translateY(-100%);
            }

            &:before {
              transform: translateY(0);
              animation: loadingState .3s;
            }
          }
        }
      }
    }

    .cropper-btn {
        pointer-events: none;
    }
  }
}


@keyframes loadingState {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes hideLoadingState {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
