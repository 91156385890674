.call-showroom-tab {
  visibility: hidden;
  position: absolute;
  transform: translateX(450px);
  width: 100%;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 12px 0 0 0;

  &.visible {
    visibility: visible;
    transform: translateX(0px);
    z-index: 1;
  }

  .call-showroom-tab-footer {
    display: flex;
    flex-flow: column;
    width: calc(100% - 24px);
    margin-top: 12px;

    .call-showroom-tab-footer-total {
      margin-top: 12px;
      border-top: 1px solid black;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-weight: 600;
        font-size: 14px;
        color: #3f3f3f;
        padding: 12px 0;
      }
    }

    .error-message {
      font-size: 14px;
      color: red;
      margin-top: 6px;
    }
  }
}