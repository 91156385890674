.modal-overlay {
  position: fixed !important;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;

  .call-details-modal {
    height: 630px;
    width: 480px;
    border-radius: 32px;
    background-color: #ffffff;
    box-shadow: inset 0 -4px 0 0 #000000;
    display: flex;
    flex-direction: column;
    position: relative;

    .select-flow {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 10%;
      margin-bottom: 20px;

      .flow-description {
        margin:4px 18px 0 18px;
      }
    }
  }
}
