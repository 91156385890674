.auth-page {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover !important;
  background: url(./../../assets/media/screen.svg) no-repeat right/contain;
  overflow: hidden;
  .brand-logo {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 42px;
  }
  .background-element {
    position: absolute;
    width: 60%;
    height: 130%;
    top: -105px;
    left: -130px;
  }
  .auth-form-container {
    display: flex;
    width: 35%;
    min-width: 376px;
    margin-top: calc(50vh - 145px);
    z-index: 10;
    justify-content: center;
    > * {
      z-index: 100;
    }
    form {
      width: 376px;
      min-width: 376px;
      height: 307px;
      h2 {
        line-height: 35px;
        display: block;
        margin-bottom: 40px;
      }
      .input-container {
        height: 136px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 40px;
      }
      .form-footer {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

.auth-header {
  border-bottom: 1px solid #9f9f9f;
}

.auth-error {
  text-align: center;
}
.auth-spinner {
  text-align: center;
}

#auth {
  margin-top: 10vh;
}
