.image-box {
  background-color: var(--main-color);
  max-width: 50%;
  border: var(--main-color) solid 1px;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  margin-right: 20px;
  .image-real {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.host-image-box {
  max-width: 50%;
  border: var(--main-color) solid 1px;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  margin-left: 20px;
  .image-real {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.loading-style {
  padding: 2px;
  width: 100px;
  height: 100px;
}

.image-placeholder {
  border-radius: 10px;
  width: 100%;
  height: 100%;

  div {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 10px !important;
  }
}
