.multiple-orders-tab {
  visibility: hidden;
  position: absolute;
  transform: translateX(450px);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  ul {
    padding: 0;
    list-style-type: none;
  }

  li {
    padding-bottom: 6px;

    &:last-child {
      padding-bottom: 0;
    }
  }

  .cart-details-tab {
    position: relative;
    overflow-y: visible;
    height: auto;
  }

  &.visible {
    visibility: visible;
    transform: translateX(0px);
    z-index: 1;
  }
}