.notification-border {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  border-style: solid;
  border-width: 3px;
  z-index: 1000;
  will-change: opacity;
  transition-property: opacity, color, border-color;

  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
  }
}

.notification-container {
  position: relative;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;

  .toast-wrapper {
    padding: 10px 0 0 0;
    position: absolute;
    will-change: transform;
  }

  .notification {
    border-radius: 4px;
    min-height: 44px;
    min-width: 490px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    position: relative;

    &.info {
      min-width: 369px;
    }

    .icon-container {
      height: 56px;
      width: 56px;
      margin: 0 4px 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 56px;

      svg {
        font-size: 32px;
      }

      &.error {
        svg {
          color: var(--error-color);
          -webkit-text-fill-color: var(--error-color);
        }
      }      
    }

    .message-wrapper {
      display: flex;
      flex-flow: column;
      align-items: center;
      text-align: center;

      &.center-text{
        width: 100%;
      }

      .title {
        font-size: 14px;
        color: white;
        -webkit-text-fill-color: white;
      }

      .description {
        font-size: 14px;
        color: #d0d0d0;
        -webkit-text-fill-color: #d0d0d0;
      }

      .shop-name {
        font-size: 11px;
        color: #d0d0d0;
        -webkit-text-fill-color: #d0d0d0;
      }

      p {
        margin: 0;
        max-width: 450px;
      }

      &.title-only {
        .title {
          line-clamp: 2;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }

    .dismiss-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 16px;
      color: #d0d0d0;
      -webkit-text-fill-color: #d0d0d0;
      z-index: 1;
    }

    .icon-container {
      svg {
        color: var(--main-color);
        -webkit-text-fill-color: var(--main-color);
      }

      &.error {
        svg {
          color: var(--error-color);
          -webkit-text-fill-color: var(--error-color);
        }
      }
    }

    &.info {
      background-color: var(--main-color);

      .message-wrapper {
        .description {
          color: white;
          -webkit-text-fill-color: white;
        }
      }

      .dismiss-button {
        color: white;
        -webkit-text-fill-color: white;

      }
    }

    &.default {
      background-color: var(--notification-color);
    }

    &.success {
      background-color: var(--notification-color);

      .icon-container {
        svg {
          color: var(--green-color);
          -webkit-text-fill-color: var(--green-color);
        }
      }
    }

    &.checkout {
      background-color: var(--notification-color);

      .icon-container {
        svg {
          color: var(--sherbert-color);
          -webkit-text-fill-color: var(--sherbert-color);
        }
      }
    }

    &.warning {
      background-color: var(--notification-color);
      color: var(--sherbert-color);
      -webkit-text-fill-color: var(--sherbert-color);

      .icon-container {
        svg {
          color: var(--sherbert-color);
          -webkit-text-fill-color: var(--sherbert-color);
        }
      }
    }

    &.error {
      background-color: var(--notification-color);
      color: var(--error-color);
      -webkit-text-fill-color: var(--error-color);

      .icon-container {
        svg {
          color: var(--error-color);
          -webkit-text-fill-color: var(--error-color);
        }
      }
    }
  }
}

@keyframes toast-in-top {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes toast-out-top {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}
