.product-sidebar {
  flex: 1 1 auto;
  overflow-y: auto;
  width: 100%;
  height: 100%;

  .customer-search-panel-back-button {
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .14s opacity;
    will-change: opacity;
    margin-bottom: 6px;

    &:hover {
      opacity: .4;
    }
  }

  .product-details-description-wrapper {
    display: grid;
    grid-gap: 12px;
    gap: 12px;
    height: 100px;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: flex-start;

    .product-title {
      width: 18vw;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow-y: hidden;
    }

    .product-description-block {
      height: 100%;
      cursor: pointer;
      overflow: hidden;
      position: relative;

      &.overflow-background {
        &:after {
          opacity: 1;
        }
      }

      .product-description {
        color: rgba(0, 0, 0, 0.64);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
        font-size: 12px;

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 14px;
        }

        & * {
          all: revert;
          margin: 0;
        }
      }

      .more-button {
        font-size: 12px;
        position: absolute;
        bottom: 0;
        z-index: 1;
        text-decoration: underline;
        left: 0;
      }

      &:after {
        content: '';
        background-image: linear-gradient(to top, #fff 18px, rgba(255, 255, 255, 0) 100%);
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        height: 40px;
        opacity: 0;
      }

    }
  }

  .product-details-error-container {
    padding: 0 4%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .product-details-container {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 37.5% 56%;
    column-gap: 6%;
    max-width: 100%;
    justify-content: center;
    align-content: flex-start;

    img {
      border-radius: 16px;
    }

    .images-container {
      display: grid;
      row-gap: 3.5%;
      height: 56%;

      img {
        cursor: pointer;
      }

      .main-image {
        object-fit: contain;
        width: 100%;
      }

      .image-gallery-wrapper {
        overflow: hidden;
        overflow-x: auto;
        height: 80px;

        .image-gallery {
          display: grid;
          overflow-x: scroll;
          overflow: auto;
          grid-auto-flow: column;
          gap: 5px;
          grid-template-rows: 60px;
          grid-template-columns: repeat(auto-fill, 40px);

          .image-thumbnail-container {
            height: 100%;
            position: relative;
            min-width: 40px;
            justify-self: center;
            max-width: 40px;

            &:before {
              content: '';
              position: absolute;
              pointer-events: none;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: 2px solid var(--main-color);
              opacity: 0;
              transition: .14s opacity;
              border-radius: 6px;
            }

            &.highlighted {
              &:before {
                opacity: 1;
              }
            }

            img {
              object-fit: cover;
              height: 100%;
              width: 100%;
              border-radius: 0;
              border-radius: 6px;
            }
          }
        }
      }

    }

    .product-details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 12px;

      .previous-purchase {
        display: flex;
        flex-direction: column;

        p {
          font-size: 14px;
        }
      }

      form {
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: 8px;
        grid-template-rows: 48px;

        select {
          font-size: 14px;
          padding: 14px 12px;
          box-sizing: border-box;
          border: 1px solid #ccc3bd;
          border-radius: 8px;
        }

        &.no-select {
          margin-top: 8px;
        }
      }
    }

    .full-size-image {
      position: absolute;
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      background: white;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .carousel-root {
        width: 90%;

        @media (min-width: 1400px) {
          width: 80%;
        }

        .carousel {
          .slider-wrapper {
            max-height: 90vh;
            border-radius: 16px;
          }

          .slide {
            background: unset;
          }

          .control-next.control-arrow {
            border-radius: 0 16px 16px 0;

            &:before {
              border-left: 8px solid black;
            }
          }

          .control-prev.control-arrow {
            border-radius: 16px 0 0 16px;

            &:before {
              border-right: 8px solid black;
            }
          }

          .carousel-status {
            visibility: hidden;
          }
        }
      }

      .image-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -24px;
      }

      div:first-child {
        width: 90%;
        display: flex;
        justify-content: flex-end;

        span {
          cursor: pointer;
        }
      }
    }
  }

  .similar-items {
    overflow-x: scroll;

    .image-gallery {
      height: 78%;
      display: flex;

      > * {
        max-height: 100%;
        border-radius: 16px;
        margin-right: 1.5%;
        width: 18%;
      }
    }
  }
}
