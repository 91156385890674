.modal-overlay {
  .modal.create-invite-modal {
    height: 630px;
    width: 480px;
    border-radius: 32px;
    background-color: #ffffff;
    box-shadow: inset 0 -4px 0 0 #000000;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 44px;
    .close-button {
      box-sizing: border-box;
      height: 30px;
      width: 30px;
      border: 1px solid #999999;
      border-radius: 16px;
      background-color: #ffffff;
      position: absolute;
      right: 24px;
      top: 24px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }
    form {
      width: 360px;
      margin: auto;
      display: flex;
      flex-direction: column;
      .inputs {
        height: 136px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 40px;
        .customer-select {
          &__control {
            height: 56px;
            border-radius: 28px;
            border: 1px solid rgba(0, 0, 0, 0.32);
            outline: none;
            &:focus {
              border: 1px solid black;
              box-shadow: none;
            }
          }
          &__placeholder {
            color: rgba(0, 0, 0, 0.32);
            -webkit-text-fill-color: rgba(0, 0, 0, 0.32);
          }
          &__menu {
            border: 1px solid #ccc3bd;
            border-radius: 28px;
            background-color: #ffffff;
            box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24);
            &-list {
              padding: 0;
            }
          }
          &__option {
            background: none !important;
          }
        }
        .option {
          width: 100%;
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          padding-left: 24px;
          margin-bottom: 8px;
          p {
            font-size: 14px;
            margin: unset;
            &:first-child {
              font-weight: 600;
            }
          }
          &:hover {
            background: rgba(0, 0, 0, 0.04);
            cursor: pointer;
          }
          &:first-child {
            border-radius: 28px 28px 0 0;
          }
          &:last-child {
            border-radius: 0 0 28px 28px;
          }
        }
      }
      .actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 60px;
        margin-bottom: 20px;
      }
      p.error-text {
        min-height: 20px;
      }
      p.warning-text {
        min-height: 20px;
        color: var(--sherbert-color);
        -webkit-text-fill-color: var(--sherbert-color);
        font-size: 14px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin: auto;
      font-size: 14px;

      footer.confirm-actions {
        border: none;
        flex-direction: row;
        justify-content: center;
        padding: 20px 0;
        > div:first-child {
          cursor: pointer;
        }
      }
      section.confirm-schedule {
        height: 85%;
        padding: 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        h2,
        p {
          color: var(--base-font-color);
          -webkit-text-fill-color: var(--base-font-color);
          margin-bottom: 16px;
          text-align: center;
        }
       .confirm-header {
          text-align: center;
       }

        .copy-link-container {
          width: 100%;
        }
      }
    }
  }
}
