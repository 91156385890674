.search-result-card {
    padding: 12px 12px 12px 0;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 1fr auto;
    gap: 16px;

    .image-container {
      height: 120px;
      width: 80px;
      position: relative;

      .product-overlay {
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 16px;
        cursor: pointer;
      }

      img {
        width: 100%;
        object-fit: contain;
        border-radius: 16px;
      }
    }

    .product-card-details {
      display: grid;
      gap: 10px;
      width: 100%;
      align-content: flex-start;

      h5 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        width: 100%;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow-y: hidden;
      }
      .price-container {
        display: flex;
        flex-flow: row wrap;

        h4 {
          font-size: 18px;
          font-weight: 600;
          line-height: 16px;
        }
        :nth-child(1) {
          color: var(--main-color);
          -webkit-text-fill-color: var(--main-color);
          margin-right: 8px;
        }
        :nth-child(2) {
          color: rgba(0, 0, 0, 0.4);
          -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
          text-decoration: line-through;
        }
      }
      span {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.64);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
        height: 28px;
      }
    }
  }
