.call-details-modal {
  justify-content: space-between;

  .text-input {
    border-radius: 32px;
    border: 1px solid var(--light-font-color);
    outline: none;
    color: black;
    -webkit-text-fill-color: black;
    padding: 16px;
    box-sizing: border-box;
    text-align: start;
    width: 100%;
    margin: 12px 12px 12px 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;

    &:focus {
      border: 1px solid black;
      box-shadow: none;
    }

    &::placeholder {
      color: var(--light-font-color);
      -webkit-text-fill-color: var(--light-font-color);
    }
  }

  .description-for-client {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .success-container {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .description-success-container {
      align-items: center;
      display: flex;
      flex-direction: column;

      .success-description {
        margin-top: 20px;
        text-align: center;
      }
    }
  }


}
