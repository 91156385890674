.login-page-container {
  height: 100%;
  width: 100%;
  position: relative;
  background-size: cover !important;
  background: url(./../../assets/media/screen.svg) no-repeat right/contain;
  overflow: hidden;
  .background-element {
    position: absolute;
    width: 60%;
    height: 130%;
    top: -105px;
    left: -130px;
  }
  .brand-logo {
    position: absolute;
    top: 40px;
    left: 40px;
    height: 42px;
  }
}

.login-page {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;

  .login-container {
    width: 376px;
    height: 333px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-left: 120px;
    z-index: 100;
    h2 {
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      line-height: 35px;
    }
    .input-container {
      width: 100%;
      height: 136px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .actions {
      width: 324px;
      display: grid;
      grid-template-columns: 200px auto;
      grid-column-gap: 64px;
      align-items: center;
      grid-template-rows: 56px 52px;
      span {
        color: var(--light-font-color);
        -webkit-text-fill-color: var(--light-font-color);
      }
      > p {
        grid-column: 1/3;
        line-height: 26px;
        font-size: 18px;
      }
    }
    &.reset-success {
      height: 400px;
      align-items: center;
      text-align: center;
      .actions {
        width: 100%;
        height: 104px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .login-header {
    border-bottom: 1px solid #9f9f9f;
  }
  .login-form-forgot {
    float: right;
    padding: 0;
  }
}
#login {
  margin-top: 10vh;
}
