.caazam-input {
  border-radius: 32px;
  height: 56px;
  border: 1px solid var(--light-font-color);
  outline: none;
  color: black;
  -webkit-text-fill-color: black;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  text-align: start;
  &:focus {
    border: 1px solid black;
    box-shadow: none;
  }
  &::placeholder {
    color: var(--light-font-color);
    -webkit-text-fill-color: var(--light-font-color);
  }
}

textarea.caazam-input {
  height: unset;
}
