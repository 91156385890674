.audio-video-settings-container {
    display: grid;
    padding: 32px;
    gap: 1em;

    &_audio-video-block {
        display: flex;
        flex-direction: row;
        flex: 1;
        align-items: flex-start;
        border: 1px solid #00000020;
        border-radius: 8px;
        padding: 1em 1em 0 1em;
    }

    .audio-video-block_audio {
        flex: 1;
        margin-right: 20px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 1em 0.4em;
        gap: 1em 0.4em;
    }

    .audio-video-block_video {
        flex: 1;
        margin-left: 20px;
    }

    .settings-section {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em .4em;

        &_network-tests {
            border: 1px solid #00000020;
            border-radius: 8px;
            padding: 1em;
        }

        .section-icon {
            grid-column: 1;
            grid-row: 1;
            align-self: center;
        }

        .section-title {
            grid-column: 2;
            grid-row: 1;
        }

        .settings-container {
            display: grid;
            grid-column: 1/3;
            gap: 1em .4em;
            margin-left: .2em;

            .section-settings-title {
                &:not(:first-child) {
                    margin-top: 2em;
                }
            }

            .manage-device-controls {
                display: flex;
                gap: 0 1em;
                flex-wrap: nowrap;
                align-items: center;

                div {
                    &:first-child {
                        flex: 0 1 400px;
                    }
                }

                video {
                    width: 320px;
                    height: 240px;
                }

                .test-button {
                    display: grid;
                    gap: 0 1em;
                    grid-auto-flow: column;
                    grid-template-columns: auto auto;
                    will-change: opacity;
                    transition: .25s opacity;

                    .test-button-label {
                        position: relative;
                        overflow: hidden;
                        display: flex;
                        width: 100px;
                        user-select: none;

                        .passive-state {
                            transform: translate3d(0, 0, 0);
                            transition: .25s ease-in-out transform;
                            will-change: transform;
                        }

                        .active-state {
                            transform: translate3d(0, -100%, 0);
                            transition: .25s ease-in-out transform;
                            will-change: transform;
                            left: 0;
                            position: absolute;
                        }
                    }

                    &.playing {
                        opacity: .4;

                        .test-button-label {
                            .passive-state {
                                transform: translate3d(0, 100%, 0);
                                transition: .14s ease-in-out transform;
                            }

                            .active-state {
                                transform: translate3d(0, 0, 0);
                                transition: .14s ease-in-out transform;

                            }
                        }
                    }


                    &:hover {
                        opacity: .4;
                    }
                }


                &.video-settings {
                    align-items: flex-start;
                    display: grid;
                    grid-auto-flow: row;
                    gap: 1em;

                    .device-selector__control,
                    .device-selector__menu {
                        width: 100%;
                        max-width: 400px;
                        flex: auto;

                    }



                    .video-element-container {
                        max-height: 240px;
                        width: 320px;
                        position: relative;
                        flex: 1;
                        flex-direction: row;
                        display: flex;
                    }
                }



            }

            /*  .device-selector {
                max-width: 400px;
            } */
            .video-init-state {
                width: 100%;
                max-width: 400px;
            }
        }

    }

    .test-running-notice {
        font-size: 14px;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}