.app-setting-row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .setting-title {
    font-size: 18px;
    color: #3f3f3f;
    -webkit-text-fill-color: #3F3F3F;
    font-weight: 500;
  }

  label {
    margin-bottom: 0;
  }

}