.history-call-item {
  list-style-type: none;
  padding: unset;
  width: 100%;

  &.history {
    gap: 4px 0;
  }

  .customers-message {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
  }

  &.scheduled {
    :first-child {
      .call-info {
        .caller-details {
          height: 100%;
          justify-content: center;
        }


        //&::after {
        //  content: 'Next call';
        //  font-size: 12px;
        //  white-space: nowrap;
        //  border-radius: 12.5px;
        //  padding: 1px 10px;
        //  background: #BDC4FF;
        //  color: #4D53AD;
        //  -webkit-text-fill-color: #4D53AD;
        //}
      }
    }
  }

  @media (max-width: 1024px) {
    margin: 24px auto;
    width: 560px;
  }

  .history-call-divider {
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;

    span {
      font-size: 14px;
      background-color: black;
      color: white;
      -webkit-text-fill-color: white;
      border-radius: 22px;
      text-align: center;
      padding: 0 8px;
    }

    div {
      height: 1px;
      flex-grow: 1;
      background-color: black;
    }

    @media (max-width: 1024px) {
      margin: 24px auto;
    }
  }
}
