input[type="checkbox"] {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.checkbox {
  display: inline-block;
  height: 20px;
  width: 20px;
  background: #fff;
  border: 2px #ddd solid;
  margin-right: 20px;
}

.checkbox--active {
  border-color: var(--main-color);
  background: white;
}

label {
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  input {
    margin-right: 20px;
  }
}
