.inventory-counter {
    justify-self: flex-start;
    align-self: flex-start;
    color: var(--base-font-color);
    -webkit-text-fill-color: var(--base-font-color);
    border: 1px solid var(--base-font-color);
    padding: 0 6px;
    font-size: 12px;

    &.warning {
      color: var(--warning-color);
      -webkit-text-fill-color: var(--warning-color);
      border: 1px solid var(--warning-color);
    }
  }
