.client-history-header {
  position: absolute;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom: 1px solid #ccc3bd;
  height: 64px;

  .back-button-container {
    display: grid;
    width: 100%;
    margin: 12px 24px;
    grid-template-columns: 180px auto 50px;

    .header-search-wrapper {
      display: flex;
      flex-flow: row;

      .customer-search-button {
        opacity: 1;
        grid-column: 2;
        border-radius: 50%;
        cursor: pointer;
        padding: 12px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        will-change: opacity;

        &.show {
          background-color: #f5f5f5;
        }

        &:disabled {
          opacity: .4;
          pointer-events: none;
        }
      }
    }


    .search-panel-form-container {
      flex-wrap: nowrap;
      width: 450px;
      transition: .4s width, .4s opacity ;
      box-sizing: border-box;
      height: 42px;
      padding: 12px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: center;
      background-color: white;
      border: 1px solid lightgray;

      &.hide {
        width: 0;
        opacity: 0;
      }

      &.show {
        width: 450px;
        opacity: 1;
      }
    }
    .search-panel-form-container-prefix {
      width: 450px;

      &__control {
        border: 1px solid rgba(0, 0, 0, 0.32);
        width: 450px;
        outline: none;
        flex-wrap: nowrap;

        &:focus {
          border: 1px solid black;
          box-shadow: none;
        }


      }
      &__placeholder {
        color: rgba(0, 0, 0, 0.32);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.32);
      }
      &__menu {
        width: 450px;
        border: 1px solid #ccc3bd;
        border-radius: 6px;
        background-color: #ffffff;
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24);
        &-list {
          padding: 0;
        }
      }
      &__option {
        background: none !important;
      }
    }

    .option {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding-left: 24px;
      p {
        font-size: 14px;
        margin: unset;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.04);
        cursor: pointer;
      }
    }

    .back-btn {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;


      .header-title {
        font-size: 20px;
        color: #3f3f3f;
      }

      .back-btn-icon {
        border: 1px solid #cfc8bb;
        color: #cfc8bb;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        margin-right: 1ch;

        svg {
          font-size: 18px;
        }
      }
    }
  }

  .top-search-client {

  }
}
