.modal-overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 102;
  .modal {
    background: white;
    border-radius: 32px;
    box-shadow: inset 0 -4px 0 0 #000000;
    padding: 32px;
    padding-top: 44px;
    z-index: 100;
    position: relative;
  }
  .close-button {
    position: absolute;
    right: 32px;
    top: 20px;
  }
  .back-modal-button {
    position: absolute;
    left: 32px;
    top: 30px;
  }

  .no-show-appointment {
    height: 250px;
  }
}
