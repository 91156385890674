.host-avatar-container-wrapper {
    position: relative;
    display: flex;

    .host-avatar-container {
        position: relative;
        height: 44px;
        width: 44px;
        overflow: hidden;
        border-radius: 50%;
        box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);

        .host-avatar {
            height: 100%;
            width: 100%;
            object-fit: contain;
            display: flex;
            justify-content: center;
            align-items: center;

            &.default {
                background: var(--sherbert-color);
            }
        }
    }

    .host-vailability-dot-wrapper {
        position: absolute;
        bottom: 0;
        right: -2px;
    }
}
