.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: var(--main-color);
      &:before {
        -webkit-transform: translateX(18px);
        transform: translateX(18px);
      }
    }
    &:focus + .slider {
      box-shadow: 0 0 1px var(--main-color);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 26px;
    &:before {
      position: absolute;
      content: '';
      height: 16px;
      width: 16px;
      left: 3px;
      bottom: 3px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
  .tooltip {
    position: absolute;
    top: 20px;
    font-size: 12px;
    text-align: center;
    width: 124px;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 300ms;
    color: black;
    -webkit-text-fill-color: black;
    border-radius: 4px;
    padding: 4px;
    background-color: white;
  }
  &:hover {
    .tooltip {
      visibility: visible;
      opacity: 0.7;
      transition: visibility 0s linear 0s, opacity 300ms;
    }
  }
}
