@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html,
input,
button,
p,
h1,
h2,
h3,
h4,
h5,
h6,
textarea {
  all: unset;
}

:root {
  --main-color: #6E76F2;
  --main-color30: #6E76F24D;
  --main-color-hover: #BDC4FF;
  --success-color: #2ed097;
  --error-color: #d83a68;
  --warning-color: #ffaa15;
  --base-font-color: rgba(0, 0, 0, 0.64);
  --light-font-color: rgba(0, 0, 0, 0.32);
  --notification-color: rgba(63, 63, 63);
  --peach-color: #FFE5D0;
  --peach-color-80: #FFE5D080;
  --peach-color-60: #FFE5D060;
  --mint-color: #C8E4E0;
  --mint-color-80: #C8E4E080;
  --midnight-color: #4D53AD;
  --sherbert-color: #FFBE88;
  --sherbert-color30: #FFBE884D;
  --green-color: #08b273;
}

#root {
  height: 100%;
  font-size: 1rem;
}

html {
  font-size: 16px;
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'Poppins', sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

button {
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
}

button:disabled {
  cursor: not-allowed;
}
