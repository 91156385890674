.conversation-summary-tab-container {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  visibility: visible;
  transform: translateX(0px);
  z-index: 1;
  padding: 12px;

  .summary-grid {
    display: grid;
    grid-gap: 6px;
  }

  p {
    font-size: 14px;
    color: #3f3f3f;
  }
}
