.showroom-preset-edit-mode-wrapper {
  display: flex;
  margin: 24px 0;
  justify-content: center;

  .filter-preset-wrapper {
    width: 100%;
    margin-left: 16px;
    display: grid;
    grid-template-columns: 1fr 16px;

    &.edit-mode {
      grid-template-columns: 1fr 70px;
    }

    .btn-plus {
      width: 40px;
      height: 40px;
      align-self: center;
      justify-self: center;
      border-radius: 50%;
      background-color: var(--main-color);
      position: relative;
      will-change: transform, opacity;
      transition: transform .5s .14s, opacity .5s .14s;
      cursor: pointer;

      &:after {
        position: absolute;
        content: '+';
        color: white;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        font-size: 40px;
      }
    }
  }
}
