.showroom-presets-modal {
  z-index: 101;
  background-color: white;
  border-left: #ccc 1px solid;
  min-width: 0;
  max-width: var(--modal-width);
  width: 0;
  min-height: 600px;
  height: 100%;
  display: grid;
  grid-template-rows: 60px auto 1fr;
  visibility: hidden;

  &.video-call-container {
    position: absolute;
    height: 100%;
  }

  &.common-container {
    height: calc(100% - 84px - 88px);
  }

  &.open {
    position: fixed;
    animation: bounce-in 0.5s forwards;
    visibility: visible;
  }

  &.close {
    position: absolute;
    animation: bounce-out 0.5s forwards;
    visibility: visible;
  }

  &.sidebar-open {
    &.video-call {
      position: absolute;
      animation: side-bar-in-video-call 500ms forwards;
    }
    &.edit-mode {
      animation: side-bar-in 500ms forwards;
    }
  }

  &.sidebar-close {
    &.video-call {
      position: absolute;
      animation: side-bar-out-video-call 500ms forwards;
    }
    &.edit-mode {
      animation: side-bar-out 500ms forwards;
    }
  }

  @keyframes side-bar-in-video-call {
    0% {
      width: 0;
      min-width: var(--modal-width);
      transform: translateX(100%);
      visibility: visible;      
    }
    100% {
      width: 100vw;
      min-width: var(--modal-width);
      transform: translateX(0);
      visibility: visible;
    }
  }

  @keyframes side-bar-out-video-call {
    0% {
      width: 100vw;
      min-width: var(--modal-width);
      transform: translateX(0);
      visibility: visible;
    }
    100% {
      width: 0;
      min-width: var(--modal-width);
      transform: translateX(100%);
      visibility: hidden;
    }
  }

  @keyframes side-bar-in {
    0% {
      width: 0;
      min-width: 0;
      transform: translateX(100%);
      visibility: visible;
    }
    100% {
      width: 100vw;
      min-width: var(--modal-width);
      transform: translateX(0);
      visibility: visible;
    }
  }

  @keyframes side-bar-out {
    0% {
      width: 100vw;
      min-width: var(--modal-width);
      transform: translateX(0);
      visibility: visible;
    }
    100% {
      width: 0;
      min-width: 0;
      transform: translateX(100%);
      visibility: hidden;
    }
  }

  @keyframes bounce-in{
    0% {
      width: 0;
      min-width: 0;
      right: -37.5vw;
    }
    100% {
      width: 100vw;
      min-width: var(--modal-width);
      right: 0;
    }
  }
  @keyframes bounce-out{
    0% {
      width: 100vw;
      min-width: var(--modal-width);
      right: 0;
    }
    100% {
      width: 0;
      min-width: 0;
      right: -37.5vw;
    }
  }

  .showroom-presets-header {
    display: grid;
    grid-template-columns: 1fr 40px;
    padding: 0 20px;
    align-items: center;
    box-shadow: 0 1px 10px rgba(0,0,0,0.1);

    .close-button {
      box-sizing: border-box;
      height: 30px;
      width: 30px;
      border: 1px solid #999999;
      border-radius: 16px;
      background-color: #ffffff;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      margin-right: 20px;
    }
  }

  .product-display {
    overflow-y: auto;

    .error-results {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      p {
        display: block;
        margin-top: 24px;
        margin-bottom: 0;

        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }

  .presets-list {
    overflow-y: auto;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    .preset-item {
      .preset-products-list {
        display: flex;
        flex-direction: row;
        overflow-x: auto;

        .main-image {
          border-radius: 10px;
          object-fit: contain;
          height: 80px;
          max-width: 60px;
        }
      }
    }
  }
}