.permissions-lock-helper-container {
  min-height: 200px;
  min-width: 600px;
  width: 50%;
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .permissions-lock-helper-info {
    font-size: 16px;
    margin-bottom: 24px;
    width: 80%;
    text-align: center;
  }

  .permissions-helper-button {
    margin-top: 12px !important;
    text-transform: capitalize !important;
  }
}
