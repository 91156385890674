.calendar-row {
  display: grid;
  grid-template-columns: 70px auto;
  column-gap: 20px;
  position: relative;
  align-items: flex-start;
  grid-template-rows: 1fr auto;
  min-height: 78px;
  cursor: pointer;

  .overflow {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 2px solid var(--main-color);
    opacity: 0;
    transition: .14s opacity;
  }

  &.selected {
    &:before {
      opacity: 1;
    }
  }

  &.short-row {
    align-items: center;
    min-height: 39px;
  }

  .shop-name {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    font-size: 11px;
    padding: 8px 0 0 10px;
    color: #66676C;
    -webkit-text-fill-color: #66676C;
  }

  .call-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    justify-content: flex-start;
    padding-top: 16px;
    color: #66676C;
    -webkit-text-fill-color: #66676C;
    font-size: 14px;

    &.with-shop-name {
      padding-top: 4px;
    }

    &.short-row {
      padding-top: 0;
    }
  }

  .call-info {
    min-width: 0;
    border-radius: 16px;
    padding: 16px 24px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    color: #66676C;
    -webkit-text-fill-color: #66676C;
    cursor: pointer;

    &.with-shop-name {
      padding: 4px 24px 12px 24px;
    }

    &.short-row {
      padding: 4px 24px;
      display: inline-block;
    }

    &:hover .scheduled-actions-container {
      visibility: visible;
      transition-delay: 0s;
    }

    .caller-details {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-right: 20px;

      .client-name-wrapper {
        flex-flow: row;
        display: inline-block;
        align-items: center;

        .customer-name {
          margin-right: 12px;
          color: black;
          -webkit-text-fill-color: black;
          flex: 1 1;

          .schedule-post-message {
            color: #66676C;
            -webkit-text-fill-color: #66676C;
          }
        }
      }

      .feedback-section {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .feedback-message-container {
          display: flex;
          overflow: hidden;
          justify-content: flex-start;
          padding: 0 0 0 14px;

          .feedback-message {
            font-size: 14px;
            list-style: disc inside none;
            display: list-item;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .feedback-hover-tooltip-text {
            visibility: hidden;
            display: inline;
            background-color: #6e76f2;
            border-radius: 4px;
            padding: 5px;
            position: absolute;
            z-index: 1;
            top: 85%;
            left: 0;
            margin-left: 60px;
            font-size: 14px;
            color: white;
            -webkit-text-fill-color: white;
            text-align: center;
            max-height: 250px;
            overflow-y: auto;
          }

          &:hover .feedback-hover-tooltip-text {
            visibility: visible;
          }
        }
      }

      .customer-contacts-container {
        font-size: 14px;
        display: flex;
        flex-flow: row nowrap;

        p {
          white-space: nowrap;
        }

        .circle-divider {
          padding: 0 10px;
          position: relative;

          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.8);
          }
        }
      }
    }

    .badge-wrapper {
      display: inline-flex;
      flex-direction: column-reverse;
    }
  }

  &.history {
    background: #F7F7F7;

    .call-info {
      &.error {
        .call-outcome {}
      }

      &.success {
        .call-outcome {
          background-color: #C8E4E0;
          color: #5F7874;
          -webkit-text-fill-color: #5F7874;
        }
      }
    }
  }

  &.scheduled {
    .scheduled-call-divider {
      display: block;
      grid-column: 1/3;
      border-top: 2px solid #EFEFEF;
    }
  }

  &.blocked {
    background: repeating-linear-gradient(
                    135deg,
                    #F7F7F7,
                    #F7F7F7 10px,
                    white 10px,
                    white 20px
    );
    .scheduled-call-divider {
      display: block;
      grid-column: 1/3;
      border-top: 2px solid #EFEFEF;
    }
  }
}
