.google-auth-settings {

  display: flex;
  align-items: center;
  justify-content: space-between;


  .google-auth-settings-text {
    display: flex;
    flex-direction: column;
    font-size: 14px;

    .title {
      font-size: 18px;
    }

    &_status {
      width: 280px;
      color: var(--light-font-color);

      &_success {
        color: var(--success-color)
      }

      &_error {
        color: var(--error-color)
      }
    }

  }

  .google-auth-button {
    display: flex;
    flex-direction: row;
    margin-top: 4px;

    .loading {
      padding: 6px 10px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      margin: auto;
      border: 2px solid white;
      border-top: 2px solid var(--main-color);
      animation: button-loader 2s linear infinite;
    }

    &_button {
      width: 230px;
      align-items: center;
      justify-content: flex-start;
      padding: 6px 10px;
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-radius: 4px;
      -webkit-box-shadow: 1px 1px 1px 1px var(--light-font-color);
      -moz-box-shadow: 1px 1px 1px 1px var(--light-font-color);
      box-shadow: 1px 1px 1px 1px var(--light-font-color);

      &_disabled {
        pointer-events: none;
        opacity: 0.7;
        background-color: var(--light-font-color);
      }

      &:hover {
        background-color: rgba(189, 195, 199, 0.2);
      }

      p {
        font-weight: 500;
      }
    }
  }
}


