.customer-profile-skeleton-container {
  display: flex;
  align-items: center;
  flex-flow: column;

  .customer-profile-skeleton-row-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    &.underline {
      border-bottom: 1px solid #c0c0c0;
    }
  }

  .customer-profile-description-skeleton {
    margin-top: 20px;
    width: 100%;
  }

  .customer-profile-recommendation-skeleton {
    display: flex;
    flex-direction: row;
    width: 100%;

  }
}
