.video-room-container {
  display: flex;
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  .video-room-screen {
    height: calc(100vh - 80px);
    width: 62.5%;
    transition: all 0.4s ease-in-out;
  }
  &.ftr-open {
    .video-room-screen {
      height: calc(100vh - 310px);
    }
  }
  .drawer-container {
    background-color: #ffffff;
    position: absolute;
    bottom: 0;
    z-index: 100;
    width: 62.5%;
    transform: translateY(540px);
    transition: 0.4s ease-in-out;
    &.product-view {
      transform: translateY(310px);
      button.ftr-open {
        transform: rotate(180deg);
      }
    }
    &.cart-view {
      transform: translateY(0);
      button.ftr-open {
        transform: rotate(180deg);
      }
    }
    .drawer-header {
      height: 80px;
      background-color: var(--peach-color-60);
      box-sizing: border-box;
      border: 1px solid #ccc3bd;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 40px;
      .start {
        font-weight: 600;
        color: rgba(0, 0, 0, 0.4);
        -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
        display: flex;
        height: 100%;
        > span {
          display: flex;
          box-sizing: border-box;
          cursor: pointer;
          height: 100%;
          &.without-client-tag {
            align-items: center;
          }
          &.with-client-tag {
            align-items: flex-end;
          }
          .client-tag-block {
            text-align: center;
            margin-bottom: 3px;
            margin-top: -3px;
            h4 {
              color: white;
              -webkit-text-fill-color: white;
              font-size: 14px;
              background-color: var(--main-color);
              border: var(--main-color) 1px solid;
              padding: 2px 5px 1px 5px;
              border-radius: 4px;
            }
          }
          > p {
            margin-right: 6px;
            line-height: 18px;
          }
          .counter {
            display: inline-block;
            text-align: center;
            box-sizing: border-box;
            height: 20px;
            width: 20px;
            min-width: 20px;
            border: 1px solid rgba(0, 0, 0, 0.4);
            border-radius: 50%;
            font-size: 12px;
            line-height: 18px;
            margin-left: 5px;
            &.emphasize {
              color: white;
              -webkit-text-fill-color: white;
              background-color: black;
            }
          }
          &.non-active {
            padding-bottom: 3px;
          }
          &.active {
            color: black;
            -webkit-text-fill-color: black;
            border-bottom: 3px solid black;
            margin-bottom: 0px;
            .counter {
              border-color: black;
            }
          }
        }

        > :nth-child(1) {
          margin-right: 44px;
        }
      }
      .end {
        p {
          margin-right: 16px;
        }
      }
      button {
        all: unset;
      }
      button.ftr-open {
        box-sizing: border-box;
        height: 49px;
        width: 49px;
        border: 1px solid #ccc3bd;
        border-radius: 50%;
        background-color: #ffffff;
        color: #cfc8bb;
        -webkit-text-fill-color: #cfc8bb;
        font-size: 48px;
        outline: none;
        line-height: 66px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .MuiSvgIcon-root {
          font-size: 2.4rem;
        }
      }
    }
    .drawer {
      height: 540px;
    }
  }

  &.checkout {
    border-width: 3px;
    border-style: solid;
    border-color: var(--sherbert-color);
  }

  &.order {
    border-width: 3px;
    border-style: solid;
    border-color: var(--green-color);
  }
}
