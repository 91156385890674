.setting-container {
    width: 100%;
    height: 100%;
    padding: 30px 0 0 48px;
    display: grid;
    gap: 1em .4em;
    margin-bottom: 1rem;

    .host-settings-item {
        border-top: 1px solid rgba(0,0,0,.14);
        padding-top: 12px;
        &:first-child {
            border-top: none;
            padding-top: 0px;
        }
        width: 95%;

    }


    .profile-avatar-setting-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin: 12px;

        .avatar-container {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -15px;
            height: 80px;
            width: 80px;
            overflow: hidden;
            border-radius: 50%;
            position: relative;
            box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, .15);
            color: white;
            -webkit-text-fill-color: white;

            &:after {
                border-radius: 50%;
                border: 4px solid white;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, .4);
            }

            .avatar {
                object-fit: cover;
                height: 78px;
                width: 78px;
                overflow: hidden;
                border-radius: 50%;
                position: relative;
            }

            .avatar-initials {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 78px;
                width: 78px;
                overflow: hidden;
                border-radius: 50%;
                position: relative;
                background: var(--sherbert-color);
                font-size: 20px;
                font-weight: 500;
            }
        }

        .upload-image {
            color: #000;
        }

        .remove-image {
            color: var(--error-color);
        }

        .button {
            text-decoration: underline;
            font-size: 18px;
            line-height: 26px;
            padding: 12px;
        }
    }

    .host-bio-input-wrapper {
        display: flex;
        flex-flow: column;
        margin-top: 12px;

        .host-bio-container {
            display: flex;
            flex-flow: column;

            .text-input {
                border-radius: 32px;
                border: 1px solid var(--light-font-color);
                outline: none;
                color: black;
                -webkit-text-fill-color: black;
                padding: 16px;
                box-sizing: border-box;
                text-align: start;
                max-width: 434px;
                width: auto;
                margin: 12px 12px 12px 0;
                overflow-wrap: break-word;
                word-wrap: break-word;
                -webkit-hyphens: auto;
                -ms-hyphens: auto;
                hyphens: auto;

                &:focus {
                    border: 1px solid black;
                    box-shadow: none;
                }

                &::placeholder {
                    color: var(--light-font-color);
                    -webkit-text-fill-color: var(--light-font-color);
                }
            }

            .char-counter {
                margin: 0 12px;
                font-size: 12px;
                opacity: .6;
            }
        }
    }

    .host-name-setting-container-wrapper {
        display: flex;
        flex-flow: column;
        margin: 12px 0 20px 0;

        .host-name-setting-container {
            display: flex;
            flex-flow: row;
            align-items: center;
            max-width: 434px;
            justify-content: space-between;

            .first-name-input-wrapper {
                width: 210px;
            }

            .last-name-input-wrapper {
                width: 210px;
            }

        }    
        .host-name-helper-text {
            margin: 0 12px;
                font-size: 12px;
                opacity: .6;
        }    
    }

    .display-name-input-wrapper {
        width: 100%;
        max-width: 434px;

        .display-name-container {
            display: flex;
            flex-flow: column;

            .display-name-helper-text {
                margin: 0 12px;
                font-size: 12px;
                opacity: .6;
            }
        }
    }

    .submit-button-wrapper {
        margin: 12px 0 0 0;

        .update-profile-button {
            &:disabled {
                background-color: var(--main-color-hover) !important;
            }

        }
    }



    .reset-passwod-button {
        position: relative;
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;
        justify-content: center;
        height: 34px;
        overflow: hidden;

        .password-reset-label {
            font-size: 18px;
            line-height: 26px;
            text-decoration: underline;
        }

        .email-successfully-sent {
            color: var(--main-color);
            -webkit-text-fill-color: var(--main-color);
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            svg {
                font-size: 38px;
                margin-right: 16px;
            }

            .succeed-message {
                font-size: 22px;
                line-height: 32px;
                font-weight: 500;
            }
        }

        &.show-message {}
    }
}
