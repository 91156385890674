.permissions-container {
  min-height: 400px;
  min-width: 600px;
  width: 50%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .permissions-logo {
    width: 64px;
    height: 64px;
    margin-bottom: 12px;
  }

  .permissions-title {
    font-weight: bold;
    font-size: 22px;
  }

  .permissions-info {
    top: 0;
    font-size: 16px;
    margin-bottom: 24px;
    width: 80%;
    text-align: center;
  }

  .permissions-button {
    margin-top: 12px !important;
    text-transform: capitalize !important;
  }
}
