.available-slot-skeleton-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-flow: column;

  .available-slot-skeleton-date-selector-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .available-slot-skeleton-slots-container {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #c0c0c0;
    border-top: 1px solid #c0c0c0;
    padding: 20px 0 10px 0;
    flex: 1;
    flex-flow: column;

    .available-slot-skeleton-slot {
      margin-bottom: 10px;
    }
  }
}

