.modal-container {
  height: 90%;
  width: 70%;
  background-color: black;
  border-radius: 10px;
  border: white 1px solid;
  position: absolute;
  left: 270px;
  z-index: 1000;
  display: flex;
  justify-content: center;

  .close-icon {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
    z-index: 100;
    color: white;
    -webkit-text-fill-color: white;
    height: 32px;
    width: 32px;
    border: white 1px solid;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .image-container {
    width: 70%;
    margin-top: 50px;
    margin-bottom: 10px;

    .image-real {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.top {
  top: -20px;
}

.video-top {
  top: 10px;
}