button {
  cursor: pointer;
}

.customer-name {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.App {
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 770px;
  * {
    box-sizing: border-box;
  }
  .price-container {
    display: flex;
    :nth-child(1) {
      color: var(--main-color);
      -webkit-text-fill-color: var(--main-color);
      margin-right: 8px;
    }
    :nth-child(2) {
      color: rgba(0, 0, 0, 0.4);
      -webkit-text-fill-color: rgba(0, 0, 0, 0.4);
      text-decoration: line-through;
    }
  }
  .error-text {
    color: var(--error-color) !important;
    -webkit-text-fill-color: var(--error-color) !important;
    font-size: 14px;
  }

  .clickable {
    cursor: pointer;
  }

  .click-outside {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    right: 0;
    display: none;
    &.visible {
      display: block;
      z-index: 99;
    }
  }

  div {
    &.button-loader {
      animation: button-loader 2s linear infinite;
    }
  }
  @keyframes button-loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  h1 {
    font-size: 28px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
  ul {
    margin: unset;
  }
}

@keyframes onenter-list-item-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
