$dot-size: 16px;

.host-vailability-dot {
    height: $dot-size;
    width: $dot-size;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 2px;
        right: 2px;
        bottom: 2px;
        left: 2px;
        border-radius: 50%;
        opacity: 0;
        will-change: opacity;
        transition: .14s opacity;
    }
    &:before {
        background-color: var(--main-color);
    }

    &:after {
        background-color: var(--error-color);
    }

    &.available {
        &:before {
            opacity: 1;
        }
    }

    &.unavailable {
        &:after {
            opacity: 1;
        }
    }
}
