.video-room-sidebar-wrapper {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding: 12px 24px;

  .video-room-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    h4 {
      font-weight: unset;
    }


    .customer-panel-header {
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 1fr auto;
      padding: 0;
  
      .customer-search-button {
        grid-column: 2;
        border-radius: 50%;
        cursor: pointer;
        padding: 12px;
        margin: 8px 0;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        transition: .2s opacity;
        will-change: opacity;
    
        &:disabled {
          opacity: .4;
          pointer-events: none;
        }
      }
  
      .customer-details {
        display: flex;
        flex-direction: column;
        background-color: white;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 40px;
        overflow: hidden;
    
        .back-button {
          box-sizing: border-box;
          height: 30px;
          width: 30px;
          border: 1px solid #999999;
          border-radius: 16px;
          background-color: #ffffff;
          position: absolute;
          left: 26px;
          top: 26px;
          font-weight: 600;
          font-size: 20px;
          line-height: 26px;
        }

          h2 {
          margin: 15px 0;
          font-size: 22px;
          font-weight: 600;
          text-align: center;
          width: 100%;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
        }
    
        h3 {
          font-size: 16px;
          color: rgba(0, 0, 0, 0.64);
          -webkit-text-fill-color: rgba(0, 0, 0, 0.64);
          text-align: center;
          width: 100%;
          overflow: hidden;
          display: inline-block;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .customer-profile {
      display: flex;
      flex-direction: column;
      overflow-y: auto;

      .customer-profile-anonymous-profile {
        margin: 24px 0;
        p {
          color: #66676C;
          font-size: 14px;
        }
      }
    }

    .purchase-data {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      flex-flow: row nowrap;
      width: 100%;
      max-width: 560px;
      align-self: center;
    }

    .customer-data {
      display: grid;
      gap: 2px 12px;
      margin-bottom: 10px;
      padding: 0;
  
      .data-item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid #E8E8E8;
        padding-top: 6px;
        padding-bottom: 6px;
  
        .field-label {
          display: grid;
          align-items: center;
          grid-auto-flow: column;
          gap: 0 8px;
          margin-right: 1ch;
          justify-content: flex-start;
          max-width: 20ch;
          flex-shrink: 0;
  
          svg {
            width: 27px;
          }
          .title-container {
            display: grid;
            grid-auto-flow: column;
            align-items: baseline;
  
            .title {
              display:inline-block;
              font-size: 16px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              color: #66676C;
            }
          }
        }
  
        .value {
          overflow-y: auto;
          max-height: 4.5em;
          font-size: 16px;
          max-width: 100%;
          overflow-wrap: break-word;
          word-break: break-word;
          hyphens: auto;
          color: #3F3F3F;
        }
      }
    }
  
    .product-display {
      display: grid;
      grid-auto-flow: row;
      padding: 0;
      .product-container {
        overflow-x: auto;
        .product-view {
          display: flex;
          height: 100%;
          .image-with-overlay {
            width: calc(20vh * 0.66);
            min-width: calc(20vh * 0.66);
            height: 20vh;
            margin: 1vh;
          }
          .image-with-overlay-no-click {
            width: calc(15vh * 0.66);
            min-width: calc(15vh * 0.66);
            height: 15vh;
            margin: 1vh;
          }
        }
      }
    }
  }
}


.small {
  min-width: 140px;
  min-height: 55px;
}
.big {
  min-width: 160px;
  min-height: 55px;
}
