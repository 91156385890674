.search-panel-form-container {
    box-sizing: border-box;
    height: 42px;
    padding: 12px 0;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    background-color: #80767617;
    width: 100%;

    .search-panel-input-field[type="search"] {
        margin-left: 16px;
        height: 100%;
        width: 100%;
        outline: none;
        border: none;
        font-size: 16px;
        color: black;
    }

    .search-panel-input-field[type=search] {
        -webkit-appearance: none;
    }

    /* clears the ‘X’ from Internet Explorer */
    .search-panel-input-field[type=search]::-ms-clear {
        display: none;
        width: 0;
        height: 0;
    }

    .search-panel-input-field[type=search]::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
    }

    /* clears the ‘X’ from Chrome */
    .search-panel-input-field[type="search"]::-webkit-search-decoration,
    .search-panel-input-field[type="search"]::-webkit-search-cancel-button,
    .search-panel-input-field[type="search"]::-webkit-search-results-button,
    .search-panel-input-field[type="search"]::-webkit-search-results-decoration {
        display: none;
    }

    .search-panel-submit-form-btn {
        color: #66676c;
        -webkit-text-fill-color: #66676c;
        margin-left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
